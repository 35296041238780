// Function to transform and serialize the input with dynamic values based on extended dataType
export function transformAndSerializeInput(
  input: Array<{ name: string; dataType: string; _id: string }>
) {
  // Transform input into the desired format based on dataType
  const transformed = input.reduce((acc, { name, dataType }) => {
    switch (dataType) {
      case 'number':
        acc[name] = 0; // Default number
        break;
      case 'string':
        acc[name] = ''; // Default string
        break;
      case 'boolean':
        acc[name] = false; // Default boolean
        break;
      case 'date':
        acc[name] = new Date().toISOString(); // Current date in ISO format
        break;
      case 'array of strings':
        acc[name] = []; // Default empty array
        break;
      case 'array of objects':
        acc[name] = []; // Default empty array for objects
        break;
      case 'object':
        acc[name] = {}; // Default empty object
        break;
      default:
        acc[name] = null; // Default null for unrecognized types
    }
    return acc;
  }, {} as Record<string, any>);

  // Serialize the transformed data into a JSON string without the 'input_data' key
  const serializedTemplate = JSON.stringify(transformed, null, 2);

  return serializedTemplate;
}
