import React from 'react';
import DynamicPopupDialog from './DynamicPopupDialog';
import Button from './Button';
import DropdownByEndpoint from './DropdownByEndpoint';
import styled from 'styled-components';
import { postData } from '../utils/axios';
import { useDynamicQueryMutation } from '../redux/api/authSlice';

const PopupWrapper = styled.div`
  display: grid;
  gap: 10px;
  button {
    justify-self: flex-end;
  }
`;

const Error = styled.div`
  margin-top: 10px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
`;

const TestDataTool = () => {
  const [show, setShow] = React.useState(false);
  const [object, setObject] = React.useState({});
  const [dynamicQuery, result] = useDynamicQueryMutation();
  let res: any = result;

  const onRun = async () => {
    try {
      await dynamicQuery({
        endpoint: '/ai/saveTest',
        method: 'POST',
        body: {
          data: object,
        },
      });
    } catch (error) {}
  };

  return (
    <div>
      <Button theme="page" text="Run Tests" onClick={() => setShow(true)} />
      <DynamicPopupDialog isOpen={show} onClose={setShow}>
        <PopupWrapper>
          <DropdownByEndpoint
            onChange={(e: any) =>
              setObject((prev) => ({ ...prev, functionId: e?.value }))
            }
            endpoint="/ai/assistant"
            labelProperty="assistant"
            valueProperty="assistantId"
            title="Filter Assistant"
          />
          <DropdownByEndpoint
            onChange={(e: any) =>
              setObject((prev) => ({ ...prev, applyAssistant: e?.value }))
            }
            endpoint="/ai/assistant"
            labelProperty="assistant"
            valueProperty="assistantId"
            title="Apply Assistant"
          />
          <Button theme="page" text="Run Tests" onClick={onRun} />
        </PopupWrapper>
        <Error>
          Status: {res?.status} {JSON.stringify(res?.error)}
        </Error>
      </DynamicPopupDialog>
    </div>
  );
};

export default TestDataTool;
