/* eslint-disable styled-components-a11y/no-onchange */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dropdown from 'react-select';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectGlobal,
  setCurrentHTML,
  setHtmlTagValues,
} from '../../redux/state/globalSlice';
import { ReadOnly } from './ReadOnlyStyle';
import { postData } from '../../utils/axios';

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
`;

const Wrapper = styled.div`
  margin: 1rem 0;
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

interface DropdownControlProps extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const DynamicDropdownFetch: React.FC<DropdownControlProps> = (props: any) => {
  const uiOptions = props.schema?.['ui:options'];

  const [options, setOptions] = useState<any>([]);
  const [optionsPerm, setOptionsPerm] = useState([]);
  const [lock, setLock] = useState<boolean>(true);

  const [dynamicQuery, result] = useDynamicQueryMutation();
  const global = useAppSelector(selectGlobal);
  const dispatch = useAppDispatch();

  const handleSelectChange = (event: any) => {
    props.handleChange(
      props.path,
      uiOptions?.isMulti ? event?.map((ctx: any) => ctx?.value) : event?.value
    );
  };
  // Assuming fetchOptions is a function that returns a Promise that resolves to the array of options
  const fetchOptions = async () => {
    try {
      dynamicQuery({
        endpoint: props?.uischema?.extentedAPI || uiOptions?.endpoint,
        method: 'POST',
        body: {
          foundObject: global?.rowObject,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, [uiOptions?.endpoint]);

  useEffect(() => {
    if (props?.path === 'pg_tmp') {
      postData('/getHTML', { id: props.data }).then((dt) =>
        dispatch(setCurrentHTML(dt?.source))
      );
      if (Object.keys(global?.htmlTagValues)?.length === 0) {
        dispatch(setHtmlTagValues(global?.currentFormState?.htmlTagValues));
      }
    }

    if (result.status === 'fulfilled') {
      setOptions(result?.data?.data);
      setOptionsPerm(result?.data?.data);
    }
  }, [result]);
  console.log(props?.path);

  // CONDITIONS

  useEffect(() => {
    if (props?.path === 'then_keys') {
      setOptions((prev: any) => [
        ...optionsPerm?.filter(
          (ctx: any) =>
            ctx?.collection_id ===
            global?.currentFormStateOnUpdate?.source_collection
        ),
      ]);
    }

    if (props?.path === 'engine_model') {
      setOptions((prev: any) => [
        ...optionsPerm?.filter(
          (ctx: any) =>
            ctx?.engineId === global?.currentFormStateOnUpdate?.engineId
        ),
      ]);
    }

    if (props?.path?.includes('.target_property')) {
      setOptions((prev: any) => [
        ...optionsPerm?.filter(
          (ctx: any) =>
            ctx?.collection_name ===
            global?.currentFormStateOnUpdate?.target_collection
        ),
      ]);
    }

    if (props?.path?.includes('.source_property')) {
      setOptions((prev: any) => [
        ...optionsPerm?.filter(
          (ctx: any) =>
            ctx?.collection_name ===
              global?.currentFormStateOnUpdate?.source_collection &&
            // Apply dataType filter only if input_type includes 'array of'
            (global?.currentFormState?.input_type?.includes('array first') &&
            props?.path?.includes('0.source_property')
              ? ctx?.dataType?.includes('array of')
              : true)
        ),
      ]);
    }

    if (
      props?.path?.includes('.function_property') ||
      props?.path?.includes('.run_step_request_property')
    ) {
      setOptions((prev: any) => [
        ...optionsPerm?.filter((ctx: any) =>
          global?.currentFormStateOnUpdate?.runIds?.includes(ctx?.functionId)
        ),
      ]);
    }

    if (
      props?.path?.includes('.request_object_property') ||
      props?.path?.includes('.data_function_response_property')
    ) {
      setOptions((prev: any) => [
        ...optionsPerm?.filter((ctx: any) =>
          global?.currentFormStateOnUpdate?.enrichment_function?.includes(
            ctx?.functionId
          )
        ),
      ]);
    }

    if (props?.path?.includes('.request_property')) {
      setOptions((prev: any) => [
        ...optionsPerm?.filter(
          (ctx: any) =>
            global?.currentFormStateOnUpdate?.runIds?.includes(
              ctx?.functionId
            ) &&
            (global?.currentFormState?.input_type?.includes('array first') &&
            props?.path?.includes('0.request_property')
              ? ctx?.dataType === 'string' || ctx?.dataType === 'objects'
              : true)
        ),
      ]);
    }

    if (props?.path?.includes('.next_step') || props?.path === 'else_next') {
      setOptions((prev: any) => [
        ...optionsPerm?.filter(
          (ctx: any) =>
            global?.currentFormStateOnUpdate?.WorkflowId === ctx?.WorkflowId
        ),
      ]);
    }
  }, [result, global?.currentFormStateOnUpdate, optionsPerm]);

  useEffect(() => {
    if (
      props?.path?.includes('.request_object_property') &&
      !global?.currentFormStateOnUpdate?.requestDataTypes?.length
    ) {
      props.handleChange('requestDataTypes', result?.data?.data);
    }
  }, [result?.isLoading]);

  if (result?.isLoading && props?.visible) {
    return <p>{uiOptions?.title} are getting fetched...</p>;
  }

  const dropdownOptions =
    props?.path === 'source_collection'
      ? [
          { value: 'Jobs Triggers', label: 'Jobs Triggers' },
          ...options.map((option: any) => ({
            value: option?.[uiOptions?.value], // Assuming this is a string that uniquely identifies this option
            label: option?.[uiOptions?.label], // The label that will be displayed in the dropdown
          })),
        ]
      : options.map((option: any) => ({
          value: option?.[uiOptions?.value], // Assuming this is a string that uniquely identifies this option
          label: option?.[uiOptions?.label], // The label that will be displayed in the dropdown
        }));

  let currentSelectedOption = dropdownOptions.find(
    (option: any) => option.value === props.data
  );

  return props?.visible ? (
    <Wrapper>
      <Label htmlFor={props.id}>{uiOptions?.title}</Label>
      <Dropdown
        id={props.id}
        options={dropdownOptions}
        isClearable
        onChange={(e) => handleSelectChange(e)}
        value={
          uiOptions?.isMulti
            ? props.data?.map((ctx: any) =>
                dropdownOptions.find((option: any) => option?.value === ctx)
              )
            : currentSelectedOption
        }
        placeholder="Select..."
        maxMenuHeight={200}
        isMulti={uiOptions?.isMulti}
      />
      {/* <Select id={props.id} value={props.data} onChange={handleSelectChange}>
        <option>Select...</option>
        {options.map((option: any, index: any) => (
          <option key={index} value={option?.assistant_type}>
            {option?.assistant_type}
          </option>
        ))}
      </Select> */}
    </Wrapper>
  ) : (
    <div />
  );
};

export default withJsonFormsControlProps(DynamicDropdownFetch);
