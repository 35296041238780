import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import withState from './components/HOCS/withState';
import DynamicPage from './pages/DynamicPage';
import { useDynamicQueryMutation } from './redux/api/authSlice';
import DeleteWarning from './components/DeleteWarning';
import NoAuth from './pages/NoAuth';
import { HelmetProvider } from 'react-helmet-async';
import './index.scss';
const LandingPage = React.lazy(() => import('./pages/LandingPage'));

const Login = React.lazy(() => import('./pages/Login'));
const Layout = React.lazy(() => import('./components/Layout'));
const ProtectedRoutes = React.lazy(() => import('./pages/ProtectedRoute'));

function App({ globalState, dispatch }: any) {
  const [dynamicQuery, result] = useDynamicQueryMutation();
  const { authenticated } = globalState;
  const { user } = authenticated;
  // Refresh Token
  React.useEffect(() => {
    // everytime page refreshed
    dynamicQuery({ endpoint: '/refreshToken', method: 'POST', body: {} });
    // when page is not refreshed
    const interval = setInterval(() => {
      dynamicQuery({ endpoint: '/refreshToken', method: 'POST', body: {} });
    }, 1000 * 60 * 15);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    if (result.status === 'fulfilled') {
      localStorage.setItem('token', result?.data?.token);
    }
  }, [result]);

  React.useEffect(() => {
    let title = '';

    if (window.location.host === 'localhost:3000') {
      title = 'Partner Portal - Local';
    } else if (window.location.host === 'dev.cognni.ai') {
      title = 'Partner Portal - Dev';
    } else if (window.location.host === 'kb.inforisks.com') {
      title = 'KB Portal';
    } else if (window.location.host === 'cms.inforisks.com') {
      title = 'CMS Portal';
    } else if (window.location.host === 'reports-cms.inforisks.com') {
      title = 'Report CMS';
    } else if (window.location.host === 'partner.cognni.ai') {
      title = 'Partner Portal';
    } else if (window.location.host === 'functions.ai-api.co') {
      title = 'AI Functions';
    } else if (window.location.host === 'audience.reacher.bz') {
      title = 'Audience Intelligence';
    }

    document.title = title || 'Default Title'; // Set the document title based on the conditions
  }, []);

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <Suspense fallback={<div>LOADING...</div>}>
            <Login />
          </Suspense>
        }
      />

      <Route
        path="/lp/:slug"
        element={
          <Suspense fallback={<div>LOADING...</div>}>
            <LandingPage />
          </Suspense>
        }
      />
      <Route element={<Layout />}>
        <Route element={<ProtectedRoutes />}>
          {user?.pages ? (
            user?.pages?.map((pg: any) => (
              <Route
                path={pg?.route}
                element={
                  <DynamicPage
                    testDataApply={pg?.pageRef?.testDataApply}
                    timeBased={pg?.timeBased}
                    breadcrumbs={pg?.breadcrumbs}
                    addEnabled={pg?.addEnabled}
                    updateDataEndpoint={pg?.updateTableEndpoint}
                    dispatch={dispatch}
                    globalState={globalState}
                    funnelActive={pg?.pageRef?.funnelActive}
                    runWorkFlow={pg?.pageRef?.runWorkFlow}
                    filtersActive={pg?.pageRef?.filtersActive}
                    dataTableActive={pg?.pageRef?.dataTableActive}
                    imageGalleryActive={pg?.pageRef?.imageGalleryActive}
                    REST={pg?.pageRef?.REST}
                    formCondition={pg?.pageRef?.formCondition}
                    formREST={pg?.pageRef?.formREST}
                    formConditionWorkflow={pg?.pageRef?.formConditionWorkflow}
                    formConditionOptions={pg?.formConditionOptions}
                    functions={pg?.functions}
                    filtersList={pg?.filtersRef?.list}
                    dataTable={pg?.dataTableRef}
                    barChartActive={pg?.pageRef?.BarChart}
                    origin={pg?.origin}
                    endpoint={pg?.endpoint || '/'}
                    api={pg?.api}
                    assistantEndpoint={pg?.assistantEndpoint}
                    actionsMenu={pg?.actionsMenu}
                    actionsMenuEnabled={pg?.actionsMenuEnabled}
                    cvsEndpoint={pg?.cvsEndpoint}
                    replaceByParam={pg?.replaceByParam}
                    barChartProperty={pg?.barChartProperty}
                    popupData={pg?.popupData}
                    funnel={pg?.funnel}
                  />
                }
              />
            ))
          ) : (
            <Route path="*" element={<p>LOADING...</p>} />
          )}
        </Route>
      </Route>
      <Route path="/no-auth" element={<NoAuth />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}

export default withState(App);
