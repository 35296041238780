/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectGlobal, setHtmlTagValues } from '../../redux/state/globalSlice';
import styled from 'styled-components';
import { postData } from '../../utils/axios';
import DropdownByEndpoint from '../DropdownByEndpoint';

interface DataType {
  name: string;
  type: 'text' | 'long text' | 'image' | 'data';
}

// Define styled components for the table
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  &:nth-child(odd) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  max-width: 200px;
`;

const Input = styled.textarea`
  width: 100%;
  padding: 1rem;
  resize: vertical;
`;

const CustomContentTagData: React.FC = () => {
  const [dataTypes, setDataTypes] = useState<DataType[]>([]);
  const [data, setData] = useState<any>();
  const { currentHTML, pg_tmp, htmlTagValues } = useAppSelector(selectGlobal);
  const dispatch = useAppDispatch();

  useEffect(() => {
    try {
      postData('/getHTML', { id: pg_tmp });
    } catch (error) {}
  }, []);

  const extractDataTypes = useCallback((html: string): DataType[] => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // An array to store the extracted data types
    const extractedData: DataType[] = [];

    // Extracting custom tokens
    doc.querySelectorAll('h1, p, img, div').forEach((element: any) => {
      if (
        element.tagName.toLowerCase() === 'img' &&
        element.getAttribute('src')?.includes('##')
      ) {
        // If the element is an image with a source including the $$ token
        const token = element.getAttribute('src')?.slice(2);
        if (token) {
          extractedData.push({ name: `##${token}`, type: 'image' });
        }
      } else {
        // For all other elements, get their innerText
        const text = element?.innerText.trim();
        const firstChar = text.charAt(0);

        if (firstChar === '#') {
          if (text.startsWith('##')) {
            // This case might not be needed if $$ is only used for images
            extractedData.push({ name: `##${text.slice(2)}`, type: 'image' });
          }
        }
      }
    });

    return extractedData;
  }, []);

  useEffect(() => {
    if (currentHTML?.length) {
      const extractedData = extractDataTypes(currentHTML);
      setDataTypes(extractedData);
    }
  }, [currentHTML, extractDataTypes]);

  return (
    <Table>
      <tbody>
        {dataTypes.map((dataType, index) => (
          <TableRow key={index}>
            <TableCell>{dataType.name}</TableCell>
            <TableCell>
              <DropdownByEndpoint
                zIndex={99}
                pickedValue={htmlTagValues?.[dataType.name]}
                endpoint="/getDataObjects"
                labelProperty="name"
                valueProperty="name"
                onChange={(e: any) =>
                  dispatch(setHtmlTagValues({ [dataType.name]: e?.value }))
                }
              />
            </TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

export default memo(CustomContentTagData);
