// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/funnel
import { ResponsiveFunnel } from '@nivo/funnel';
import { ResponsiveBar } from '@nivo/bar';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { FadeInDiv } from '../style/Animations';

const Wrapper = styled.div`
  display: flex;
  width: 750px;
  height: 250px;
  position: relative;
`;

const RightCounter = styled.div`
  align-self: center;
  width: 150px;
`;

const Title = styled.p`
  color: rgba(41, 55, 72, 1);
  text-align: center;
  width: 100%;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.01em;
`;

const Loading = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 600px;
  height: 250px;
  color: rgba(41, 55, 72, 1);
`;

const ItemsOnLine: any = styled.div`
  z-index: 1;
  width: ${({ statMode }: any) => (statMode ? '100%' : '100%')};
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  position: absolute;
  left: 0;
  bottom: ${(props: any) => (props.bottom ? props.bottom + 'px' : 'unset')};
`;
const Item = styled.button`
  color: rgba(41, 55, 72, 1);
  text-align: center;
  width: 100%;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: center;
  background: none;
  cursor: pointer !important;
`;

const ItemValue = styled(Item)`
  color: rgba(251, 116, 127, 1);
`;

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const upcomingRenewals = [
  {
    id: 1,
    value: 100,
    label: 'In 4 months',
    origin: '/upcoming-renewals/management',
    route: '/4month',
  },
  {
    id: 2,
    value: 100,
    label: 'In 3 months',
    origin: '/upcoming-renewals/management',
    route: '/3month',
  },
  {
    id: 3,
    value: 100,
    label: 'In 2 months',
    origin: '/upcoming-renewals/management',
    route: '/2month',
  },
  {
    id: 4,
    value: 100,
    label: 'This month',
    origin: '/upcoming-renewals/management',
    route: '/1month',
  },
];

const payingCustomers = [
  {
    id: 1,
    value: 100,
    label: '2022-Q3',
    origin: '/paying-customers/management',
    route: '/q1',
  },
  {
    id: 2,
    value: 100,
    label: '2022-Q4',
    origin: '/paying-customers/management',
    route: '/q2',
  },
  {
    id: 3,
    value: 100,
    label: '2023-Q1',
    origin: '/paying-customers/management',
    route: '/q3',
  },
  {
    id: 4,
    value: 100,
    label: '2023-Q2',
    origin: '/paying-customers/management',
    route: '/q4',
  },
  {
    id: 5,
    value: 100,
    label: 'last-30-days',
    origin: '/paying-customers/management',
    route: '/last-30-days',
  },
];

const onboardedDeals = [
  {
    id: 1,
    value: 100,
    label: '2022-Q3',
    origin: '/onboarded-deals/management',
    route: '/q1',
  },
  {
    id: 2,
    value: 100,
    label: '2022-Q4',
    origin: '/onboarded-deals/management',
    route: '/q2',
  },
  {
    id: 3,
    value: 100,
    label: '2023-Q1',
    origin: '/onboarded-deals/management',
    route: '/q3',
  },
  {
    id: 4,
    value: 100,
    label: '2023-Q2',
    origin: '/onboarded-deals/management',
    route: '/q4',
  },
  {
    id: 5,
    value: 100,
    label: 'last-30-days',
    origin: '/onboarded-deals/management',
    route: '/last-30-days',
  },
];

const openDeals = [
  {
    id: 1,
    value: 100,
    label: '2022-Q3',
    origin: '/open-deals/management',
    route: '/q1',
  },
  {
    id: 2,
    value: 100,
    label: '2022-Q4',
    origin: '/open-deals/management',
    route: '/q2',
  },
  {
    id: 3,
    value: 100,
    label: '2023-Q1',
    origin: '/open-deals/management',
    route: '/q3',
  },
  {
    id: 4,
    value: 100,
    label: '2023-Q2',
    origin: '/open-deals/management',
    route: '/q4',
  },
  {
    id: 5,
    value: 100,
    label: 'last-30-days',
    origin: '/open-deals/management',
    route: '/last-30-days',
  },
];

// Lost

const lost = [
  {
    id: 1,
    value: 100,
    label: '2022-Q3',
    origin: '/lost/management',
    route: '/q1',
  },
  {
    id: 2,
    value: 100,
    label: '2022-Q4',
    origin: '/lost/management',
    route: '/q2',
  },
  {
    id: 3,
    value: 100,
    label: '2023-Q1',
    origin: '/lost/management',
    route: '/q3',
  },
  {
    id: 4,
    value: 100,
    label: '2023-Q2',
    origin: '/lost/management',
    route: '/q4',
  },
  {
    id: 5,
    value: 100,
    label: 'last-30-days',
    origin: '/lost/management',
    route: '/last-30-days',
  },
];

const customersQuarter = [
  {
    id: 1,
    value: 100,
    label: '2022-Q3',
    origin: '/customers/management/customers-overview',
    route: '/q1',
  },
  {
    id: 2,
    value: 100,
    label: '2022-Q4',
    origin: '/customers/management/customers-overview',
    route: '/q2',
  },
  {
    id: 3,
    value: 100,
    label: '2023-Q1',
    origin: '/customers/management/customers-overview',
    route: '/q3',
  },
  {
    id: 4,
    value: 100,
    label: '2023-Q2',
    origin: '/customers/management/customers-overview',
    route: '/q4',
  },
  {
    id: 5,
    value: 100,
    label: 'last-30-days',
    origin: '/customers/management/customers-overview',
    route: '/last-30-days',
  },
];

const onboardedRecentLead = [
  {
    id: 1,
    value: 100,
    label: '2022-Q3',
    origin: '/leads-recent/management',
    route: '/q1',
  },
  {
    id: 2,
    value: 100,
    label: '2022-Q4',
    origin: '/leads-recent/management',
    route: '/q2',
  },
  {
    id: 3,
    value: 100,
    label: '2023-Q1',
    origin: '/leads-recent/management',
    route: '/q3',
  },
  {
    id: 4,
    value: 100,
    label: '2023-Q2',
    origin: '/leads-recent/management',
    route: '/q4',
  },
  {
    id: 5,
    value: 100,
    label: 'last-30-days',
    origin: '/leads-recent/management',
    route: '/last-30-days',
  },
];

const onboardedRecent = [
  {
    id: 1,
    value: 100,
    label: '2022-Q3',
    origin: '/onboarded-recent/management/customers-overview',
    route: '/q1',
  },
  {
    id: 2,
    value: 100,
    label: '2022-Q4',
    origin: '/onboarded-recent/management/customers-overview',
    route: '/q2',
  },
  {
    id: 3,
    value: 100,
    label: '2023-Q1',
    origin: '/onboarded-recent/management/customers-overview',
    route: '/q3',
  },
  {
    id: 4,
    value: 100,
    label: '2023-Q2',
    origin: '/onboarded-recent/management/customers-overview',
    route: '/q4',
  },
  {
    id: 5,
    value: 100,
    label: 'last-30-days',
    origin: '/onboarded-recent/management/customers-overview',
    route: '/last-30-days',
  },
];

const dataPipeLineLead = [
  {
    id: 1,
    value: 100,
    label: 'Lead',
    origin: '/leads',
    route: '/management',
  },
  {
    id: 2,
    value: 0,
    label: 'Qualified',
    origin: '/leads',
    route: '/management/qualified',
  },
  {
    id: 3,
    value: 0,
    label: 'Demo Provided',
    origin: '/leads',
    route: '/management/demo-provided',
  },
];
const dataPipeLineLeadQual = [
  {
    id: 1,
    value: 100,
    label: 'Lead',
    origin: '/leads-qualified',
    route: '/management',
  },
  {
    id: 2,
    value: 0,
    label: 'Qualified',
    origin: '/leads-qualified',
    route: '/management/qualified',
  },
  {
    id: 3,
    value: 0,
    label: 'Demo Provided',
    origin: '/leads-qualified',
    route: '/management/demo-provided',
  },
];

const dataPipeLine30 = [
  // {
  //   id: 1,
  //   value: 100,
  //   label: 'Lead',
  //   origin: '/onboarded/management/customers-overview',
  //   route: '/lead',
  // },
  // {
  //   id: 2,
  //   value: 0,
  //   label: 'Qualified',
  //   origin: '/onboarded/management/customers-overview',
  //   route: '/qualified',
  // },
  // {
  //   id: 3,
  //   value: 0,
  //   label: 'Demo Provided',
  //   origin: '/onboarded/management/customers-overview',
  //   route: '/demo-provided',
  // },
  {
    id: 4,
    value: 0,
    label: 'onboarded',
    origin: '/recent-onboardings/management/customers-overview',
    route: '/onboarded',
  },
  {
    id: 5,
    value: 0,
    label: 'review-completed',
    origin: '/recent-onboardings/management/customers-overview',
    route: '/review-completed',
  },
  {
    id: 6,
    value: 0,
    label: 'quote-sent',
    origin: '/recent-onboardings/management/customers-overview',
    route: '/quote-sent',
  },
  {
    id: 7,
    value: 0,
    label: 'verbal-approval',
    origin: '/recent-onboardings/management/customers-overview',
    route: '/verbal-approval',
  },
  {
    id: 8,
    value: 0,
    label: 'won',
    origin: '/recent-onboardings/management/customers-overview',
    route: '/won',
  },
];
const dataPipeLineQualified = [
  // {
  //   id: 1,
  //   value: 100,
  //   label: 'Lead',
  //   origin: '/onboarded/management/customers-overview',
  //   route: '/lead',
  // },
  // {
  //   id: 2,
  //   value: 0,
  //   label: 'Qualified',
  //   origin: '/onboarded/management/customers-overview',
  //   route: '/qualified',
  // },
  // {
  //   id: 3,
  //   value: 0,
  //   label: 'Demo Provided',
  //   origin: '/onboarded/management/customers-overview',
  //   route: '/demo-provided',
  // },
  {
    id: 4,
    value: 0,
    label: 'onboarded',
    origin: '/qualified/management/customers-overview',
    route: '/onboarded',
  },
  {
    id: 5,
    value: 0,
    label: 'review-completed',
    origin: '/qualified/management/customers-overview',
    route: '/review-completed',
  },
  {
    id: 6,
    value: 0,
    label: 'quote-sent',
    origin: '/qualified/management/customers-overview',
    route: '/quote-sent',
  },
  {
    id: 7,
    value: 0,
    label: 'verbal-approval',
    origin: '/qualified/management/customers-overview',
    route: '/verbal-approval',
  },
  {
    id: 8,
    value: 0,
    label: 'won',
    origin: '/qualified/management/customers-overview',
    route: '/won',
  },
];
const dataPipeLineTop = [
  {
    id: 1,
    value: 100,
    label: 'Lead',
    origin: '/top-funnel/management/customers-overview',
    route: '/lead',
  },
  {
    id: 2,
    value: 0,
    label: 'Qualified',
    origin: '/top-funnel/management/customers-overview',
    route: '/qualified',
  },
  {
    id: 3,
    value: 0,
    label: 'Demo Provided',
    origin: '/top-funnel/management/customers-overview',
    route: '/demo-provided',
  },
  {
    id: 4,
    value: 0,
    label: 'onboarded',
    origin: '/top-funnel/management/customers-overview',
    route: '/onboarded',
  },

  {
    id: 8,
    value: 0,
    label: 'won',
    origin: '/top-funnel/management/customers-overview',
    route: '/won',
  },
];

const dataPipeLine = [
  // {
  //   id: 1,
  //   value: 100,
  //   label: 'Lead',
  //   origin: '/onboarded/management/customers-overview',
  //   route: '/lead',
  // },
  // {
  //   id: 2,
  //   value: 0,
  //   label: 'Qualified',
  //   origin: '/onboarded/management/customers-overview',
  //   route: '/qualified',
  // },
  // {
  //   id: 3,
  //   value: 0,
  //   label: 'Demo Provided',
  //   origin: '/onboarded/management/customers-overview',
  //   route: '/demo-provided',
  // },
  {
    id: 4,
    value: 0,
    label: 'onboarded',
    origin: '/onboarded/management/customers-overview',
    route: '/onboarded',
  },
  {
    id: 5,
    value: 0,
    label: 'review-completed',
    origin: '/onboarded/management/customers-overview',
    route: '/review-completed',
  },
  {
    id: 6,
    value: 0,
    label: 'quote-sent',
    origin: '/onboarded/management/customers-overview',
    route: '/quote-sent',
  },
  {
    id: 7,
    value: 0,
    label: 'verbal-approval',
    origin: '/onboarded/management/customers-overview',
    route: '/verbal-approval',
  },
  {
    id: 8,
    value: 0,
    label: 'won',
    origin: '/onboarded/management/customers-overview',
    route: '/won',
  },
];

const dataRenews = [
  {
    id: 0,
    value: 0,
    label: 'upcoming',
    origin: '/renews/management/customers-overview',
    route: '/upcoming',
  },
  {
    id: 1,
    value: 0,
    label: 'renewal-next-quarter',
    origin: '/renews/management/customers-overview',
    route: '/renewal-next-quarter',
  },
  {
    id: 2,
    value: 0,
    label: 'renewal-this-quarter',
    origin: '/renews/management/customers-overview',
    route: '/renewal-this-quarter',
  },
  {
    id: 3,
    value: 0,
    label: 'renewal-this-month',
    origin: '/renews/management/customers-overview',
    route: '/renewal-this-month',
  },
  {
    id: 4,
    value: 0,
    label: 'Renewed',
    origin: '/renews/management/customers-overview',
    route: '/renewed',
  },
];

const dataUpsells = [
  {
    id: 1,
    value: 0,
    label: 'Customers',
    origin: '/upsells/management/customers-overview',
    route: '/customers',
  },
  {
    id: 2,
    value: 0,
    label: 'Upsell-Opportunity',
    origin: '/upsells/management/customers-overview',
    route: '/upsell-opportunity',
  },
  {
    id: 3,
    value: 0,
    label: 'Upsell-Won',
    origin: '/upsells/management/customers-overview',
    route: '/upsell-won',
  },
];

interface IFunnel {
  orgData: any;
  circleMode: number;
  funnel: any;
}

const CandleStick: React.FC<IFunnel> = ({ orgData, circleMode, funnel }) => {
  const [stats, setStats] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [refreshLock, setRefreshLock] = useState(true);
  const params = useParams();

  // navigator
  const navigate = useNavigate();

  const handleRedirect = ({ route }: { route: string }) =>
    navigate(route, { replace: true });

  useEffect(() => {
    setStats(orgData?.stats);
    setLoading(false);
  }, [orgData]);
  useEffect(() => {
    if (stats && funnel?.type) {
      const dataSource =
        (funnel?.type === 'lost' && lost) ||
        (funnel?.type === 'onboarded-deals' && onboardedDeals) ||
        (funnel?.type === 'open-deals' && openDeals) ||
        (funnel?.type === 'paying-customers' && payingCustomers) ||
        (funnel?.type === 'upcoming-renewals' && upcomingRenewals) ||
        (funnel?.type === 'customers-quarter' && customersQuarter) ||
        (funnel?.type === 'lead-recent' && onboardedRecentLead) ||
        (funnel?.type === 'onboarded-recent' && onboardedRecent) ||
        (funnel?.type === 'lead-qual' && dataPipeLineLeadQual) ||
        (funnel?.type === 'lead' && dataPipeLineLead) ||
        (funnel?.type === 'top-funnel' && dataPipeLineTop) ||
        (funnel?.type === 'qualified' && dataPipeLineQualified) ||
        (funnel?.type === 'pipeline30' && dataPipeLine30) ||
        (funnel?.type === 'pipeline' && dataPipeLine) ||
        (funnel?.type === 'renews' && dataRenews) ||
        (funnel?.type === 'upsells' && dataUpsells) ||
        [];

      setFilteredData(
        dataSource?.map(
          (dt: any) =>
            (dt.value =
              stats?.find(
                (st: any) =>
                  st?.stage?.toUpperCase() === dt?.label?.toUpperCase()
              )?.[circleMode === 0 ? 'totalUsers' : 'totalOrgs'] || 0)
        )
      );
    }
  }, [circleMode, stats]);

  useEffect(() => {
    if (funnel?.refreshPageOnChange && !refreshLock) {
      window.location.reload();
    }
    setRefreshLock(false);
    return () => setRefreshLock(true);
  }, [params?.quarter]);

  if (!stats) {
    return (
      <FadeInDiv>
        <Loading>LOADING...</Loading>
      </FadeInDiv>
    );
  }

  const data =
    (funnel?.type === 'lost' &&
      lost?.map((dt: any, index: number) => ({
        ...dt,
        route: '/' + orgData?.quarters?.[index],
      }))) ||
    (funnel?.type === 'onboarded-deals' &&
      onboardedDeals?.map((dt: any, index: number) => ({
        ...dt,
        route: '/' + orgData?.quarters?.[index],
      }))) ||
    (funnel?.type === 'paying-customers' &&
      payingCustomers?.map((dt: any, index: number) => ({
        ...dt,
        route: '/' + orgData?.quarters?.[index],
      }))) ||
    (funnel?.type === 'open-deals' &&
      openDeals?.map((dt: any, index: number) => ({
        ...dt,
        route: '/' + orgData?.quarters?.[index],
      }))) ||
    (funnel?.type === 'upcoming-renewals' &&
      upcomingRenewals?.map((dt: any, index: number) => dt)) ||
    (funnel?.type === 'customers-quarter' &&
      customersQuarter?.map((dt: any, index: number) => ({
        ...dt,
        route: '/' + orgData?.quarters?.[index],
      }))) ||
    (funnel?.type === 'lead-recent' &&
      onboardedRecentLead?.map((dt: any, index: number) => ({
        ...dt,
        route: '/' + orgData?.quarters?.[index],
      }))) ||
    (funnel?.type === 'onboarded-recent' &&
      onboardedRecent?.map((dt: any, index: number) => ({
        ...dt,
        route: '/' + orgData?.quarters?.[index],
      }))) ||
    (funnel?.type === 'lead-qual' &&
      dataPipeLineLeadQual?.map((dt: any, index: number) => dt)) ||
    (funnel?.type === 'lead' &&
      dataPipeLineLead?.map((dt: any, index: number) => dt)) ||
    (funnel?.type === 'qualified' &&
      dataPipeLineQualified?.map((dt: any, index: number) => dt)) ||
    (funnel?.type === 'top-funnel' &&
      dataPipeLineTop?.map((dt: any, index: number) => dt)) ||
    (funnel?.type === 'pipeline30' &&
      dataPipeLine30?.map((dt: any, index: number) => dt)) ||
    (funnel?.type === 'pipeline' &&
      dataPipeLine?.map((dt: any, index: number) => dt)) ||
    (funnel?.type === 'renews' &&
      dataRenews?.map((dt: any, index: number) => dt)) ||
    (funnel?.type === 'upsells' &&
      dataUpsells?.map((dt: any, index: number) => dt)) ||
    [];

  return (
    <Wrapper>
      {/* <ItemsOnLine statMode={orgData?.rightCounter?.totalOrgs >= 0}>
        {funnel?.positive
          ?.map((fn: any, index: any) =>
            orgData?.quarters?.length > 0
              ? {
                  ...fn,
                  title: orgData?.quarters?.[index],
                  route: fn?.route?.replace(
                    /[^/]*$/,
                    orgData?.quarters?.[index]
                  ),
                }
              : fn
          )
          ?.map((upper: any) => (
            <Item onClick={() => upper.route && navigate(upper.route)}>
              {upper?.title}
            </Item>
          ))}
      </ItemsOnLine> */}

      <ResponsiveBar
        data={data?.map((d: any, index: number) => ({
          country: orgData?.quarters[index],
          [orgData?.quarters[index]]:
            orgData?.stats?.find(
              (s: any) =>
                s?.stage?.toLowerCase() ===
                orgData?.quarters[index]?.toLowerCase()
            )?.[circleMode === 0 ? 'totalUsers' : 'totalOrgs'] || 0,
          route: d?.route,
          origin: d?.origin,
        }))}
        keys={data?.map((d: any, index: number) => orgData?.quarters[index])}
        indexBy="country"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        onClick={(e: any) => {
          handleRedirect({ route: e.data.origin + e.data.route });
        }}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={[
          'rgba(162, 237, 135, 0.9)',
          'rgba(144, 228, 114, 0.9)',
          'rgba(105, 220, 110, 0.9)',
          'rgba(55, 195, 61, 0.9)',
          'rgba(47, 176, 52, 0.9)',
          'rgba(162, 237, 135, 0.9)',
          'rgba(144, 228, 114, 0.9)',
          'rgba(105, 220, 110, 0.9)',
        ]}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Timeline',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        barAriaLabel={(e: any) =>
          e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
        }
      />
      <ItemsOnLine bottom={30}>
        {funnel?.negative?.map((bottom: any) => (
          <Item onClick={() => bottom.route && navigate(bottom.route)}>
            {bottom?.title}
            {bottom?.title && (
              <ItemValue>
                {stats?.find(
                  (st: any) =>
                    st?.stage?.toUpperCase() === bottom?.title?.toUpperCase()
                )?.[circleMode === 0 ? 'totalUsers' : 'totalOrgs'] || 0}
              </ItemValue>
            )}
          </Item>
        ))}
      </ItemsOnLine>
    </Wrapper>
  );
};

export default CandleStick;
