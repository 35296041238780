/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { postData, fetchData } from '../utils/axios';
import LoadingComponent from '../components/Loader';
import { useAppSelector } from '../redux/hooks';
import { selectGlobal } from '../redux/state/globalSlice';
import { log } from 'console';
import { useDynamicQueryMutation } from '../redux/api/authSlice';
import SessionTokenComponent from '../components/SessionTokenComponent';
import { transformAndSerializeInput } from '../utils/JsonTemplator';
import { compareJsonProperties } from '../utils/functionTests';
const CryptoJS = require('crypto-js');

// Compute the MD5 hash of a string

const Container = styled.div`
  display: grid;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  max-width: 900px;
  width: 100%;
  margin: 40px auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fade-in 2000ms forwards;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100;
    }
  }
`;

const FormItem = styled.div`
  margin-bottom: 20px;
  display: grid;
  gap: 10px;
  button {
    justify-self: flex-start;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 350px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`;

const PlayButton = styled.button`
  justify-self: flex-end;
  background-color: #2d2d2d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  margin-top: 10px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #1e1e1e;
  }

  img {
    width: 16px;
    height: 16px;
  }

  span {
    color: #fff;
    margin-left: 8px;
    font-size: 16px;
  }
  p {
    color: #fff;
  }
  :disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const Button: any = styled.button`
  padding: 10px 20px;
  color: #333;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.2s;
  border-bottom: 2px #0057b31c solid;
  &:disabled {
    border-bottom: 2px #0056b3 solid;
  }
  img {
    width: 10px;
  }
`;

const Error = styled.div`
  margin-top: 10px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
`;

const CodeEditorContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #282c34;
  border-radius: 8px;
  color: #61dafb;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  overflow-x: auto;
`;

const CodeEditorTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
`;

const TabsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 1rem;
`;

const formatJSON = (json: any) => {
  return json?.replace(/"(\w+)"\s*:/g, (_: any, match: any) => {
    return `<span style="color: #f08d49">"${match}"</span>:`;
  });
};

const TABS = [
  {
    id: 0,
    name: 'Run Function',
  },
  {
    id: 1,
    name: 'Request Object',
  },
  {
    id: 2,
    name: 'Response Object',
  },
  {
    id: 3,
    name: 'Session Tokens',
  },
  {
    id: 4,
    name: 'Expected Returned Object',
  },
  {
    id: 5,
    name: 'Test Result',
  },
];

const compareObjects = (object1: any, object2: any) => {
  const obj1Stringfy = JSON.stringify(object1);
  if (obj1Stringfy === object2) {
    return true;
  }
  return false;
};

function safeParse(jsonString: any, returnString?: boolean) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return returnString ? '' : {}; // or any other fallback object you deem appropriate
  }
}

const Rest = ({
  DATA,
  api,
  assistantEndpoint,
}: {
  DATA: any;
  api: any;
  assistantEndpoint: any;
}) => {
  const [objects, setObjects] = useState<any>({});
  const [response, setResponse] = useState<any>();
  const [expectedReturnedObject, setExpectedReturnedObject] = useState<any>();
  const [isLoading, setIsloading] = useState(false);
  const [dynamicInputs, setDynamicInputs] = useState<string[]>([]);
  const [tabId, setTabId] = useState(0);
  const [optionsByFetch, setOptionsByFetch] = useState<any>({});
  const [assistantType, setAssistantType] = useState<string>();
  const [assistantObj, setAssistantObj] = useState<any>({});
  const [JSONerr, setJSONerr] = useState<any>();
  const [showSessionTokens, setShowSessionTokens] = useState(false);
  const [testResult, setTestResult] = useState<any>();
  const [requestTemplate, setReq] = useState<any>('');

  const [sessionTokens, setSessionTokens] = useState<any>();
  const [updatedReturnedObject, setReturnedObject] = useState<any>({});
  const [isResponseSame, setIsResponseSame] = useState(true);
  useState<number>();
  const [optionsByFetchConst, setOptionsByFetchConst] = useState<any>();

  const [dynamicQuery, result] = useDynamicQueryMutation();

  const formattedObjects = formatJSON(
    JSON.stringify(
      {
        ...objects,
        request_object: safeParse(objects?.request_object || `{}`),
      },
      null,
      2
    )
  );
  const formattedResponse = formatJSON(JSON.stringify(response, null, 2));
  const formattedReturnedObject = formatJSON(
    JSON.stringify(expectedReturnedObject)
  );

  let res: any = result;

  const global = useAppSelector(selectGlobal);

  const handleCodeChange = (event: any) => {
    const modified = event.target.innerText.replace(/\n/g, '');
    setIsResponseSame(compareObjects(response?.returnedObject, modified));
    setReturnedObject(modified);
  };

  const handleChange = (selectedOption: any, property: string) => {
    setObjects((prevState: any) => ({
      ...prevState,
      [property]: selectedOption,
    }));
  };

  const handleAddInput = () => {
    setDynamicInputs([...dynamicInputs, '']);
  };

  const handleDynamicInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    property: string
  ) => {
    const newDynamicInputs = [...dynamicInputs];
    newDynamicInputs[index] = e.target.value;

    setObjects((prevState: any) => ({
      ...prevState,
      [property]: newDynamicInputs,
    }));
    setDynamicInputs(newDynamicInputs);
  };

  const onSubmit = async () => {
    setIsloading(true);
    const generatedKey = CryptoJS.MD5(Date.now().toString()).toString();
    try {
      setObjects((prevState: any) => ({
        ...prevState,
        key: generatedKey,
      }));
      const dataPost = await postData(api, {
        ...objects,
        key: generatedKey,
        request_object: JSON.parse(objects?.request_object),
      });
      setResponse(dataPost);
      setExpectedReturnedObject(dataPost?.returnedObject);
    } catch (error: any) {
      setResponse(error?.response?.data || error?.message || error); // Set the exact response from the server
    } finally {
      setIsloading(false);
    }
  };

  const onUpdate = async () => {
    setIsloading(true);
    const generatedKey = CryptoJS.MD5(Date.now().toString()).toString();
    try {
      dynamicQuery({
        endpoint: '/ai/saveTestData',
        method: 'POST',
        body: {
          data: {
            functionId: objects?.functionId, // the functionId / assistantId
            inputData: objects?.inputData,
            expectedResponse: updatedReturnedObject,
          },
        },
      });
    } catch (error: any) {
      setResponse(error?.response?.data || error?.message || error); // Set the exact response from the server
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    // Default object set
    setObjects((prevState: any) => ({
      ...prevState,
      channelUUID: global?.authenticated?.user?.selectedPartner?.uuid,
      key: CryptoJS.MD5(Date.now().toString()).toString(),
      functionId: '',
      secretKey: CryptoJS.MD5(
        global?.authenticated?.user?.selectedPartner?.uuid +
          global?.authenticated?.user?.selectedPartner?.privateKey
      ).toString(),
    }));

    const dataOptions = DATA?.functions?.filter(
      (dt: any) => dt?.options_endpoint
    );

    dataOptions.map(async (df: any) => {
      const fechedOptions = await fetchData(assistantEndpoint);
      setOptionsByFetch((prev: any) => ({
        ...prev,
        [df?.property]: fechedOptions?.data?.map((fo: any) => ({
          value: fo?.[df?.option_property],
          label: fo?.[df?.option_property],
          type: fo?.assistant_type,
          functionId: fo?.assistantId,
          classificationType: fo?.assistantTypeObject?.classification_type,
          assistant: fo,
        })),
      }));
      // Constant Memory
      setOptionsByFetchConst((prev: any) => ({
        ...prev,
        [df?.property]: fechedOptions?.data?.map((fo: any) => ({
          value: fo?.[df?.option_property],
          label: fo?.[df?.option_property],
          type: fo?.assistant_type,
          assistant: fo,
        })),
      }));
    });
  }, []);

  useEffect(() => {
    if (assistantEndpoint?.includes('type=')) {
      const jsonTemplate = transformAndSerializeInput(
        assistantObj?.requestObject || []
      );

      setObjects((prev: any) => ({
        ...prev,
        request_object: jsonTemplate,
      }));
    } else {
      if (!objects?.request_object) {
        setObjects((prev: any) => ({
          ...prev,
          request_object: JSON.stringify(
            { input_data: '##USER_INPUT_DATA' },
            null,
            2
          ),
        }));
      }
    }
  }, [assistantObj]);

  useEffect(() => {
    // Validate request object or input data

    const jsonTemplate = transformAndSerializeInput(
      assistantObj?.requestObject || []
    );

    const compareJSON = compareJsonProperties(
      jsonTemplate,
      objects?.request_object
    );

    if (assistantEndpoint?.includes('type=')) {
      setJSONerr(
        compareJSON?.error || JSON.stringify(compareJSON?.missing) || null
      );
    }

    if (!assistantEndpoint?.includes('type=')) {
      setJSONerr(
        typeof safeParse(objects?.request_object, true) === 'object'
          ? null
          : 'Invalid JSON Format'
      );
    }
  }, [objects?.request_object]);

  useEffect(() => {
    const compareJSON = compareJsonProperties(
      assistantEndpoint?.includes('type=')
        ? objects?.request_object
        : assistantObj?.tokens?.returned_object,
      JSON.stringify(response?.returnedObject)
    );
    setTestResult(compareJSON);
  }, [response]);

  // useEffect(() => {
  //   if (objects['key']) {
  //     // When type is selected filter by type the assistant from the options.
  //     const filtered = optionsByFetchConst?.['assistant']?.filter(
  //       (as: any) => as?.type === objects['key']
  //     );
  //     // setOptionsByFetch((prev: any) => ({ ...prev, assistant: filtered }));
  //   }
  // }, [objects]);

  return (
    <Container>
      <TabsWrapper>
        {TABS?.filter(
          (t) => t?.id !== 2 && t?.id !== 4 && t?.id !== 3 && t?.id !== 5
        ).map((tab: any) => (
          <Button
            disabled={tabId === tab.id}
            onClick={() => {
              setTabId(tab.id);
            }}
          >
            {tab.name}
          </Button>
        ))}
        {response &&
          !isLoading &&
          TABS?.filter((t) => t?.id === 2).map((tab: any) => (
            <Button
              disabled={tabId === tab.id}
              onClick={() => {
                setTabId(tab.id);
              }}
            >
              {tab.name}
            </Button>
          ))}
        {showSessionTokens &&
          TABS?.filter((t) => t?.id === 3).map((tab: any) => (
            <Button
              disabled={tabId === tab.id}
              onClick={() => {
                setTabId(tab.id);
              }}
            >
              {tab.name}
            </Button>
          ))}
        {response &&
          !isLoading &&
          TABS?.filter((t) => t?.id === 4).map((tab: any) => (
            <Button
              disabled={tabId === tab.id}
              onClick={() => {
                setTabId(tab.id);
              }}
            >
              {tab.name}
            </Button>
          ))}
        {response &&
          !isLoading &&
          TABS?.filter((t) => t?.id === 5).map((tab: any) => (
            <Button
              disabled={tabId === tab.id}
              onClick={() => {
                setTabId(tab.id);
              }}
            >
              {tab.name}
            </Button>
          ))}
      </TabsWrapper>
      {tabId === 0 &&
        DATA?.functions.map((item: any, index: any) => {
          switch (item.type) {
            case 'input':
              return (
                <FormItem key={index}>
                  <Label>{item.label}</Label>
                  <Input
                    type="text"
                    onChange={(e) =>
                      handleChange(e.target.value, item.property)
                    }
                  />
                </FormItem>
              );
            case 'assistant_type':
              return (
                <FormItem key={index}>
                  <Label>{item.label}</Label>
                  <Select
                    value={{
                      value: assistantType,
                      label: assistantType,
                    }}
                    options={optionsByFetch[item?.property] || item.options}
                    onChange={(selectedOption: any) => {
                      setObjects((prevState: any) => ({
                        ...prevState,
                        functionId: selectedOption?.functionId,
                      }));
                      setAssistantType(selectedOption?.value);
                      setAssistantObj(selectedOption?.assistant);

                      if (
                        selectedOption?.classificationType
                          ?.toLowerCase()
                          ?.includes('dynamic')
                      ) {
                        setShowSessionTokens(true);
                      } else {
                        setShowSessionTokens(false);
                      }
                    }}
                    placeholder="Select an option"
                  />
                </FormItem>
              );

            // case 'function-type':
            //   return (
            //     <FormItem key={index}>
            //       <Label>{item.label}</Label>
            //       <Select
            //         value={{
            //           value: objects?.[item?.property],
            //           label: objects?.[item?.property],
            //         }}
            //         options={optionsByFetch[item?.property] || item.options}
            //         onChange={(selectedOption: any) =>
            //           handleChange(selectedOption?.value, item.property)
            //         }
            //         placeholder="Select an option"
            //         isSearchable={false}
            //       />
            //     </FormItem>
            //   );

            case 'inputs':
              return (
                <FormItem key={index}>
                  <Label>{item.label}</Label>
                  {dynamicInputs.map((inputValue, idx) => (
                    <Input
                      key={idx}
                      type="text"
                      value={objects?.[item?.property]}
                      onChange={(e) =>
                        handleDynamicInputChange(e, idx, item.property)
                      }
                      placeholder={`Input ${idx + 1}`}
                    />
                  ))}
                  <Button onClick={handleAddInput}>Add Input</Button>
                </FormItem>
              );
            case 'textarea':
              return (
                <FormItem key={index}>
                  <Label>{item.label}</Label>
                  <p style={{ color: 'red' }}> {JSONerr}</p>
                  <TextArea
                    defaultValue={JSON.stringify(
                      { input_data: '##USER_INPUT_DATA' },
                      null,
                      2
                    )}
                    value={objects?.[item?.property]}
                    onChange={(e) => {
                      // let arrayValue = [e.target.value]
                      handleChange(e.target.value, item.property);
                    }}
                  />
                </FormItem>
              );
            default:
              return null;
          }
        })}
      {tabId === 0 && (
        <PlayButton disabled={isLoading || JSONerr} onClick={onSubmit}>
          <img src="/play-solid.svg" alt="" />
          <p>Run</p>
        </PlayButton>
      )}
      {tabId === 1 && (
        <CodeEditorContainer>
          <CodeEditorTitle>Request Object</CodeEditorTitle>
          <pre dangerouslySetInnerHTML={{ __html: formattedObjects }} />
        </CodeEditorContainer>
      )}
      <SessionTokenComponent
        display={tabId === 3}
        onChange={(e: any) =>
          e?.length &&
          setObjects((prevState: any) => ({
            ...prevState,
            sessionTokens: e,
          }))
        }
      />
      {isLoading && <LoadingComponent />}
      {response && !isLoading && tabId === 2 && (
        <CodeEditorContainer>
          <CodeEditorTitle>Response Object</CodeEditorTitle>
          <pre dangerouslySetInnerHTML={{ __html: formattedResponse }} />
        </CodeEditorContainer>
      )}
      {response && !isLoading && tabId === 4 && (
        <CodeEditorContainer>
          <CodeEditorTitle>Expected Returned Object</CodeEditorTitle>
          <pre
            contentEditable
            onInput={handleCodeChange}
            dangerouslySetInnerHTML={{ __html: formattedReturnedObject }}
          />
        </CodeEditorContainer>
      )}
      {tabId === 4 && !res?.isLoading && (
        <PlayButton disabled={isLoading} onClick={onUpdate}>
          <img src="/play-solid.svg" alt="" />
          <p>Add as Input Data Test</p>
        </PlayButton>
      )}
      {tabId === 4 && (
        <Error>
          Status: {res?.status} {JSON.stringify(res?.error)}
        </Error>
      )}
      {response && !isLoading && tabId === 5 && (
        <CodeEditorContainer>
          {JSON.stringify(testResult, null, 2)}
        </CodeEditorContainer>
      )}
    </Container>
  );
};

export default Rest;
