/* eslint-disable styled-components-a11y/no-onchange */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dropdown from 'react-select';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';
import { ReadOnly } from './ReadOnlyStyle';

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
`;

const Wrapper = styled.div`
  margin: 1rem 0;
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

interface DropdownControlProps extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const conditional: any = {
  'AI Function': {
    endpoint: '/ai/assistant',
    label: 'assistant',
    value: 'assistantId',
    isMulti: false,
  },
  'Data Function': {
    endpoint: '/ai/workflow/functions',
    label: 'function_name',
    value: 'functionId',
    isMulti: false,
  },

  'ai function': {
    endpoint: '/ai/assistant',
    label: 'assistant',
    value: 'assistantId',
    isMulti: false,
  },
  'multi rational classification': {
    endpoint: '/ai/assistant',
    label: 'assistant',
    value: 'assistantId',
    isMulti: true,
  },
  'multi ask': {
    endpoint: '/ai/assistant',
    label: 'assistant',
    value: 'assistantId',
    isMulti: true,
  },
};

const RunWorkflow: React.FC<DropdownControlProps> = (props: any) => {
  const [options, setOptions] = useState<any>([]);
  const [runTimeChangeCount, setRunTimeChangeCount] = useState(0);
  const [dynamicQuery, result] = useDynamicQueryMutation();
  const global = useAppSelector(selectGlobal);
  const runType = global?.currentFormStateOnUpdate?.run_type;

  console.log(runType);

  const handleSelectChange = (event: any) => {
    props.handleChange(
      props.path,
      event?.map((ctx: any) => ctx?.value)
    );
  };

  // Assuming fetchOptions is a function that returns a Promise that resolves to the array of options
  const fetchOptions = async () => {
    try {
      dynamicQuery({
        endpoint: conditional?.[runType]?.endpoint,
        method: 'POST',
        body: {
          foundObject: global?.rowObject,
        },
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setRunTimeChangeCount((prev) => prev + 1);

    fetchOptions();
  }, [runType]);

  useEffect(() => {
    console.log(runTimeChangeCount);

    if (runTimeChangeCount > 2) {
      props.handleChange(props.path, []);
    }
  }, [runTimeChangeCount]);

  useEffect(() => {
    setOptions([]);
    if (result.status === 'fulfilled') {
      setOptions(result?.data?.data);
    }
  }, [result]);

  if (result?.isLoading) {
    return <p>Run Functions are getting fetched...</p>;
  }

  let dropdownOptions = options.map((option: any) => ({
    value: option?.[conditional?.[runType]?.value], // Assuming this is a string that uniquely identifies this option
    label: option?.[conditional?.[runType]?.label], // The label that will be displayed in the dropdown
    function_type:
      option?.assistantTypeObject?.function_type || option?.function_type,
  }));

  if (runType === 'AI Function') {
    dropdownOptions = dropdownOptions.filter(
      (fc: any) => fc?.function_type === 'ai logic'
    );
  } else {
    dropdownOptions = dropdownOptions.filter(
      (fc: any) => fc?.function_type !== 'ai logic'
    );
  }

  if (runType === 'Data Function') {
    dropdownOptions = dropdownOptions.filter(
      (fc: any) =>
        fc?.function_type === 'Logic Function' ||
        fc?.function_type === 'Data Token' ||
        fc?.function_type === 'JavaScript'
    );
  }

  const currentSelectedOption = dropdownOptions.find(
    (option: any) => option.value === props.data
  );

  return runType && runType !== 'nothing' ? (
    <Wrapper>
      <Label htmlFor={props.id}>Run Functions</Label>
      <Dropdown
        id={props.id}
        options={dropdownOptions}
        onChange={(e) => handleSelectChange(e)}
        value={props.data?.map((ctx: any) =>
          dropdownOptions.find((option: any) => option?.value === ctx)
        )}
        isOptionDisabled={() =>
          conditional?.[runType]?.isMulti ? false : props?.data?.length > 0
        }
        placeholder="Select..."
        maxMenuHeight={200}
        isMulti={true}
      />
    </Wrapper>
  ) : (
    <div />
  );
};

export default withJsonFormsControlProps(RunWorkflow);
