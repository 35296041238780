import React from 'react';
import { rankWith, isControl, Tester, and } from '@jsonforms/core';

// Custom tester to check if the scope is an exact match to the given path
const exactScopeMatch = (path: string): Tester => {
  return (uischema: any, schema, context) => {
    // Assuming uischema.scope is the property holding the scope string
    // Check if the scope string is an exact match to the specified path
    // The exact path format may need to be adjusted based on your schema structure
    const exactPath = `#/properties/${path}`;
    return uischema.scope === exactPath;
  };
};

export const dynamicTesterMatch = (path: string) =>
  rankWith(
    3, // rank should be higher than the default renderers
    and(isControl, exactScopeMatch(path))
  );
