/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import styled from 'styled-components';
import { withJsonFormsControlProps, Control } from '@jsonforms/react';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';

const Label = styled.label`
  font-weight: bold;
  color: #333;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 2rem;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 2rem;
  font-weight: 400;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

interface ImageUploadControlProps {
  data: string;
  handleChange: (path: string, value: string) => void;
  path: string;
  id: any;
  label: any;
}

const findNumberInPath = (path: any) => {
  const numbers = path.match(/\d+/g);
  const numbersInt = numbers ? numbers.map(Number) : [];
  return numbersInt?.[0];
};

const RequestPropertyValue: React.FC<ImageUploadControlProps> = ({
  data, // Provide a default value of an empty string if data is undefined
  handleChange,
  path,
  id,
  label,
  ...rest
}) => {
  const handleTextareaChange = (event: React.ChangeEvent<any>) => {
    handleChange(path, String(event.target.value));
  };

  const { currentFormStateOnUpdate } = useAppSelector(selectGlobal);
  const { requestDataTypes, enrichment_function_input } =
    currentFormStateOnUpdate;
  const indexNumber = findNumberInPath(path);
  const objectProperty =
    enrichment_function_input?.[indexNumber]?.request_object_property;
  const dataType = requestDataTypes?.find(
    (ctx: any) => ctx?.name === objectProperty
  )?.dataType;

  return (
    <div>
      {/* <Label htmlFor={id}>{'Customer Story'}</Label> */}
      {(dataType === 'array of strings' ||
        dataType === 'long text' ||
        dataType === 'array of objects' ||
        dataType === 'object') && (
        <Textarea
          placeholder={`${label}`}
          id={id}
          value={data}
          onChange={handleTextareaChange}
        />
      )}
      {(dataType === 'string' ||
        dataType === 'dateTime' ||
        dataType === 'date') && (
        <StyledInput
          formNoValidate
          placeholder={`${label}`}
          id={id}
          value={data}
          onChange={handleTextareaChange}
        />
      )}
      {dataType === 'number' && (
        <StyledInput
          type="number"
          placeholder={`${label}`}
          id={id}
          value={data}
          onChange={handleTextareaChange}
        />
      )}
    </div>
  );
};

export default withJsonFormsControlProps(RequestPropertyValue);
