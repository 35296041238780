import React from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';

const Label = styled.label`
  font-weight: bold;
  color: #333;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 300px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  /* margin-top: 2rem; */
  font-weight: 400;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

interface ImageUploadControlProps {
  data: string;
  handleChange: (path: string, value: string) => void;
  path: string;
  id: any;
  label: any;
}

const CustomDetailsLargeAssistantInt: React.FC<ImageUploadControlProps> = ({
  data, // Provide a default value of an empty string if data is undefined
  handleChange,
  path,
  id,
  label,
  ...rest
}) => {
  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    handleChange(path, event.target.value);
  };

  const global = useAppSelector(selectGlobal);
  const currentAssistant = global?.currentAssistant;

  return (
    <div>
      <StyledLabel htmlFor={id}>{'Assistant instructions'}</StyledLabel>

      <Textarea
        placeholder={`Assistant instructions`}
        id={id}
        value={
          currentAssistant?.instructions ||
          currentAssistant?.assistantTypeObject?.instructions
        }
        onChange={handleTextareaChange}
        readOnly
      />
    </div>
  );
};

export default withJsonFormsControlProps(CustomDetailsLargeAssistantInt);
