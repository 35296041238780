import React from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';
import Button from '../Button';

const Label = styled.label`
  font-weight: bold;
  color: #333;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 300px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  /* margin-top: 2rem; */
  font-weight: 400;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const ValidationMessage = styled.div<{ passed?: boolean }>`
  color: ${(props) => (props.passed ? 'green' : 'red')};
  padding: 2px;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  /* border: 1px solid ${(props) => (props.passed ? 'green' : 'red')}; */
`;

interface ImageUploadControlProps {
  data: string;
  handleChange: (path: string, value: string) => void;
  path: string;
  id: any;
  label: any;
}

const CustomDetailsLargeAssistanWrite: React.FC<ImageUploadControlProps> = ({
  data, // Provide a default value of an empty string if data is undefined
  handleChange,
  path,
  id,
  label,
  ...rest
}) => {
  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    handleChange('tokenizedInstructions', event.target.value);
  };

  const [passed, setPassed] = React.useState<boolean>();
  const [msg, setMsg] = React.useState('');

  const global = useAppSelector(selectGlobal);
  const currentAssistant = global?.currentAssistant;
  const dataValue =
    global?.currentFormStateOnUpdate?.tokenizedInstructions ||
    currentAssistant?.instructions ||
    currentAssistant?.assistantTypeObject?.instructions;

  const requestObj = global?.currentFormStateOnUpdate?.requestObject || [];

  const handleValidation = () => {
    // Tags to ignore
    const ignoredTags = ['#request_object#', '#response_object#'];

    // Extract tags that start and end with #, excluding those in the ignored list
    const tags = dataValue
      .split('\n')
      .filter(
        (line: string) =>
          line.startsWith('#') &&
          line.endsWith('#') &&
          !ignoredTags.includes(line.trim())
      );

    // Extract the names from requestObj for comparison
    const requestObjNames = requestObj.map((obj: any) => obj.name);

    // Check if each tag is present in the requestObject names
    const missingTags = tags.filter((tag: string) => {
      // Remove '#' from the start and end of the tag and trim any whitespace
      const propertyName = tag.slice(1, -1).trim();
      // Check if the property name is not in the request object names
      return !requestObjNames.includes(propertyName);
    });

    // If there are missing tags, show an error message with the missing tags
    if (missingTags.length > 0) {
      setMsg(
        `Error: Missing tags in the request object: ${missingTags.join(', ')}`
      );
      setPassed(false);
    } else {
      // All tags are present
      setMsg('Success: All tags are present in the request object.');
      setPassed(true);
    }
  };

  return (
    <div>
      <StyledLabel htmlFor={id}>{'Instructions'}</StyledLabel>
      <Textarea
        placeholder={`Instructions`}
        id={id}
        defaultValue={dataValue}
        onChange={handleTextareaChange}
        readOnly={false}
      />
      <Button
        text="Validate"
        theme="page"
        onClick={handleValidation} // Call handleValidation when the button is clicked
      />
      {msg && <ValidationMessage passed={passed}>{msg}</ValidationMessage>}
    </div>
  );
};

export default withJsonFormsControlProps(CustomDetailsLargeAssistanWrite);
