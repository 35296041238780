/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { categorization } from '@jsonforms/examples';
import styled from 'styled-components';
import {
  materialRenderers,
  materialCells,
} from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { schema, uischema } from './your-json-definitions';
import imageUploadControlTester from './JSONForms/imageUploadControlTester';
import { ImageUploadControl } from './JSONForms/ImageUploadControl';
import { customTextareaTester } from './JSONForms/customTextareaTester';
import CustomTextareaControl from './JSONForms/TextareaControlRenderer';
import { customAccountManagerTester } from './JSONForms/customAccountManagerTester';
import customAccountManagerRenderer from './JSONForms/customAccountManagerRenderer';
import { customPartnerTester } from './JSONForms/customPartnerTester';
import customPartnerRenderer from './JSONForms/customPartnerRenderer';
import { datePaidSubTester } from './JSONForms/datePaidSubTester';
import datePaidSubRenderer from './JSONForms/datePaidSubRenderer';
import { dateWonTester } from './JSONForms/dateWonTester';
import dateWonRenderer from './JSONForms/dateWonRenderer';
import { dynamicTester } from './JSONForms/dynamicTester';
import contentRenderer from './JSONForms/contentRenderer';
import MultiTagRenderer from './JSONForms/multiTagRenderer';
import MultiTargetRenderer from './JSONForms/multiTargetRenderer';
import multiElementTypeRenderer from './JSONForms/multiElementTypeRenderer';
import { ImageUploadControlModified } from './JSONForms/ImageUploadControlModified';
import contentTypeRenderer from './JSONForms/contentTypeRenderer';
import customDetails from './JSONForms/customDetails';
import cmsContentPicker from './JSONForms/cmsContentPicker';
import storyAutomatedPick from './JSONForms/storyAutomatedPick';
import resourcesAutomatedPick from './JSONForms/resourcesAutomatedPick';
import discoveryAutomatedPick from './JSONForms/discoveryAutomatedPick';
import customKBType from './JSONForms/customKBType';
import pickArguments from './JSONForms/pickArguments';
import customReportElementType from './JSONForms/customReportElementGroup';
import customReportElementGroup from './JSONForms/customReportElementGroup';
import customReportFunctionType from './JSONForms/customReportFunctionType';
import customHTMLObject from './JSONForms/customHTMLObject';
import customReportBodyArray from './JSONForms/customReportBodyArray';
import customInputArg from './JSONForms/customInputArg';
import pickTags from './JSONForms/pickTags';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { selectGlobal, setCurrentFormState } from '../redux/state/globalSlice';
import customFilesUploader from './JSONForms/customFilesUploader';
import customSourcePick from './JSONForms/customSourcePick';
import customBackgroundPicker from './JSONForms/customBackgroundPicker';
import pickInputArgs from './JSONForms/pickInputArgs';
import customTemplatePicker from './JSONForms/customTemplatePicker';
import noSpaceInput from './JSONForms/noSpaceInput';
import multiTagRenderer from './JSONForms/multiTagRenderer';
import customFolderPicker from './JSONForms/customFolderPicker';
import noSpaceInputWCase from './JSONForms/noSpaceInputWCase';
import customHTMLObjectPreview from './JSONForms/customHTMLObjectPreview';
import customLocation from './JSONForms/customLocation';
import CustomPackageIdRenderer from './JSONForms/CustomPackageIdRenderer';
import CustomPromptId from './JSONForms/customPrompt';
import customContentTag from './JSONForms/customContentTag';
import customResources from './JSONForms/customResources';
import customReportBodyArrayTemplate from './JSONForms/customReportBodyArrayTemplate';
import customPartnerName from './JSONForms/customPartnerName';
import contentTypeRendererv2 from './JSONForms/contentTypeRendererv2';
import AssistantTypeFetch from './JSONForms/AssistantTypeFetch';
import customLocationFolder from './JSONForms/customLocationFolder';
import customDetailsLarge from './JSONForms/customDetailsLarge';
import useAssistantFunctionCondition from '../hooks/useAssistantFunctionCondition';
import customFunctionTypeDropdown from './JSONForms/customDropdownFunctionType';
import customDetailsLargeReadOnly from './JSONForms/customDetailsLargeReadOnly';
import customDetailsLargeReadOnlyTokenInt from './JSONForms/CustomDetailsLargeReadOnlyTokenInt';
import InputReadOnlyAssistantFunctionType from './JSONForms/InputReadOnlyAssistantFunctionType';
import customDetailsLargeAssistantInt from './JSONForms/customDetailsLargeAssistantInt';
import TestAssistantFetch from './JSONForms/TestAssistantFetch';
import customBackgroundSideBar from './JSONForms/customBackgroundSideBar';
import sourceCollectionFetch from './JSONForms/dynamicDropdownFetch';
import dynamicDropdownFetch from './JSONForms/dynamicDropdownFetch';
import runWorkflow from './JSONForms/runWorkflow';
import Analysis from './JSONForms/Analysis';
import customContentTagHtml from './JSONForms/customContentTagHtml';
import customContentTagText from './JSONForms/customContentTagText';
import customHTMLObjectPreviewTag from './JSONForms/customHTMLObjectPreviewTag';
import customContentTagLongText from './JSONForms/customContentTagLongText';
import customContentTagImage from './JSONForms/customContentTagImage';
import customContentTagData from './JSONForms/customContentTagData';
import WorkflowInput from './JSONForms/WorkflowInput';
import dynamicDropdownFetchWorkflow from './JSONForms/dynamicDropdownFetchWorkflow';
import TestResults from './JSONForms/TestResults';
import TestResultsFiltered from './JSONForms/TestResultsFiltered';
import customDetailsLargeAssistanWrite from './JSONForms/customDetailsLargeAssistanWrite';
import AssistantIdInput from './JSONForms/AssistantIdInput';
import StepId from './JSONForms/StepId';
import { dynamicTesterMatch } from './JSONForms/dynamicTesterMatch';
import RequestPropertyValue from './JSONForms/RequestPropertyValue';
import TextareaControlRenderer from './JSONForms/TextareaControlRenderer';

const SubmitButton: any = styled.button`
  background-color: #f0f0f0;
  color: #333;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: ${(props: any) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props: any) => (props.disabled ? 0.5 : 1)};
  margin-top: 20px;
`;

// list of renderers declared outside the App component
const renderers = [
  // other renderers...
  ...materialRenderers,
  { tester: imageUploadControlTester, renderer: ImageUploadControl },
  {
    tester: dynamicTester('test_results'),
    renderer: TestResults,
  },
  {
    tester: dynamicTester('testFiltered_results'),
    renderer: TestResultsFiltered,
  },

  {
    tester: customTextareaTester('customerStory'),
    renderer: CustomTextareaControl,
  },
  {
    tester: customTextareaTester('tests_instructions'),
    renderer: CustomTextareaControl,
  },

  {
    tester: customTextareaTester('tests_validation'),
    renderer: CustomTextareaControl,
  },
  {
    tester: customTextareaTester('answer'),
    renderer: CustomTextareaControl,
  },
  {
    tester: customTextareaTester('details'),
    renderer: customDetails,
  },
  {
    tester: customTextareaTester('returned_object'),
    renderer: customDetails,
  },
  {
    tester: customTextareaTester('testExpectedResponse'),
    renderer: customDetails,
  },
  {
    tester: customTextareaTester('testInputData'),
    renderer: customDetails,
  },

  {
    tester: customTextareaTester('returned_object_instructions'),
    renderer: customDetails,
  },
  {
    tester: customTextareaTester('tokenizedInstructions'),
    renderer: customDetailsLargeReadOnlyTokenInt,
  },

  {
    tester: customTextareaTester('instructions_assistant_type'),
    renderer: customDetailsLarge,
  },
  {
    tester: customTextareaTester('validation'),
    renderer: customDetailsLarge,
  },
  {
    tester: customTextareaTester('dont_know_assistant_type'),
    renderer: customDetails,
  },
  {
    tester: customTextareaTester('assistant_type'),
    renderer: AssistantTypeFetch,
  },

  {
    tester: customTextareaTester('testAssistant'),
    renderer: TestAssistantFetch,
  },

  // Workflow

  {
    tester: customTextareaTester('source_collection'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: customTextareaTester('function_property'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: customTextareaTester('keys'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: customTextareaTester('its_objects'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: customTextareaTester('fObjectId'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: customTextareaTester('then'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: dynamicTesterMatch('next_step'),
    renderer: dynamicDropdownFetch,
  },
  // {
  //   tester: dynamicTesterMatch('query'),
  //   renderer: TextareaControlRenderer,
  // },
  {
    tester: dynamicTesterMatch('else_next'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: customTextareaTester('target_collection'),
    renderer: dynamicDropdownFetch,
  },
  // {
  //   tester: customTextareaTester('source_type'),
  //   renderer: dynamicDropdownFetch,
  // },
  {
    tester: customTextareaTester('source_property'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: dynamicTesterMatch('request_property'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: dynamicTesterMatch('request_object_property'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: dynamicTesterMatch('data_function_response_property'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: dynamicTesterMatch('run_step_request_property'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: dynamicTesterMatch('request_property_value'),
    renderer: RequestPropertyValue,
  },
  {
    tester: customTextareaTester('engineId'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: dynamicTesterMatch('enrichment_function'),
    renderer: dynamicDropdownFetch,
  },

  {
    tester: customTextareaTester('WorkflowId'),
    renderer: WorkflowInput,
  },
  {
    tester: customTextareaTester('stepId'),
    renderer: StepId,
  },
  {
    tester: customTextareaTester('assistantId'),
    renderer: AssistantIdInput,
  },
  {
    tester: customTextareaTester('engine_model'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: customTextareaTester('target_property'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: customTextareaTester('pg_tmp'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: customTextareaTester('pg_texts'),
    renderer: customContentTagText,
  },
  {
    tester: customTextareaTester('pg_long_texts'),
    renderer: customContentTagLongText,
  },
  {
    tester: dynamicTesterMatch('code'),
    renderer: CustomTextareaControl,
  },
  {
    tester: dynamicTesterMatch('description'),
    renderer: customDetails,
  },
  {
    tester: dynamicTesterMatch('tests'),
    renderer: customDetails,
  },
  {
    tester: customTextareaTester('pg_datas'),
    renderer: customContentTagData,
  },
  {
    tester: customTextareaTester('pg_images'),
    renderer: customContentTagImage,
  },

  {
    tester: customTextareaTester('f_assistant'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: customTextareaTester('step_filter'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: customTextareaTester('source_properties'),
    renderer: dynamicDropdownFetch,
  },
  {
    tester: customTextareaTester('runIds'),
    renderer: runWorkflow,
  },
  // Workflow

  {
    tester: customTextareaTester('answer_looks_like'),
    renderer: customDetails,
  },
  {
    tester: customTextareaTester('guidance'),
    renderer: customDetails,
  },
  {
    tester: customAccountManagerTester,
    renderer: customAccountManagerRenderer,
  },
  {
    tester: customPartnerTester,
    renderer: customPartnerRenderer,
  },
  {
    tester: datePaidSubTester,
    renderer: datePaidSubRenderer,
  },
  {
    tester: dateWonTester,
    renderer: dateWonRenderer,
  },
  {
    tester: dynamicTester('content'),
    renderer: contentRenderer,
  },
  {
    tester: dynamicTester('analysis'),
    renderer: Analysis,
  },
  {
    tester: dynamicTester('story'),
    renderer: contentRenderer,
  },
  {
    tester: dynamicTester('know'),
    renderer: contentRenderer,
  },
  {
    tester: dynamicTester('do'),
    renderer: contentRenderer,
  },
  {
    tester: dynamicTester('tags'),
    renderer: MultiTagRenderer,
  },
  {
    tester: dynamicTester('targets'),
    renderer: MultiTargetRenderer,
  },
  {
    tester: dynamicTester('image'),
    renderer: ImageUploadControlModified,
  },
  {
    tester: dynamicTester('map'),
    renderer: multiElementTypeRenderer,
  },
  {
    tester: dynamicTester('contentType'),
    renderer: contentTypeRendererv2,
  },
  {
    tester: dynamicTester('contentText'),
    renderer: customDetails,
  },
  {
    tester: dynamicTester('objects'),
    renderer: cmsContentPicker,
  },
  {
    tester: dynamicTester('stories'),
    renderer: storyAutomatedPick,
  },
  {
    tester: dynamicTester('resources'),
    renderer: resourcesAutomatedPick,
  },
  {
    tester: dynamicTester('discoveries'),
    renderer: discoveryAutomatedPick,
  },
  {
    tester: dynamicTester('category'),
    renderer: customKBType,
  },
  {
    tester: dynamicTester('arguments'),
    renderer: pickArguments,
  },
  {
    tester: dynamicTester('tagsList'),
    renderer: pickTags,
  },

  {
    tester: dynamicTester('elementGroup'),
    renderer: customReportElementGroup,
  },
  {
    tester: dynamicTester('functionType'),
    renderer: customReportFunctionType,
  },
  {
    tester: dynamicTester('HTMLsource'),
    renderer: customHTMLObject,
  },
  {
    tester: dynamicTester('HTMLsourcePreview'),
    renderer: customHTMLObjectPreview,
  },
  {
    tester: dynamicTester('pg_html'),
    renderer: customHTMLObjectPreviewTag,
  },
  {
    tester: dynamicTester('body'),
    renderer: customReportBodyArrayTemplate,
  },
  {
    tester: dynamicTester('sidebar'),
    renderer: customReportBodyArray,
  },
  {
    tester: dynamicTester('header'),
    renderer: customReportBodyArray,
  },
  {
    tester: dynamicTester('footer'),
    renderer: customReportBodyArray,
  },
  {
    tester: dynamicTester('pages'),
    renderer: customReportBodyArray,
  },
  {
    tester: dynamicTester('inputArgs'),
    renderer: pickInputArgs,
  },
  {
    tester: dynamicTester('files'),
    renderer: customFilesUploader,
  },

  {
    tester: dynamicTester('source'),
    renderer: customSourcePick,
  },
  {
    tester: dynamicTester('sidebar_background'),
    renderer: customBackgroundSideBar,
  },
  {
    tester: dynamicTester('body_background'),
    renderer: customBackgroundPicker,
  },
  {
    tester: dynamicTester('template'),
    renderer: customTemplatePicker,
  },
  {
    tester: dynamicTester('folder'),
    renderer: noSpaceInput,
  },
  {
    tester: dynamicTester('content_tag_name'),
    renderer: noSpaceInput,
  },
  {
    tester: dynamicTester('assistantFuncTypeRead'),
    renderer: InputReadOnlyAssistantFunctionType,
  },
  {
    tester: dynamicTester('assistantInstructRead'),
    renderer: customDetailsLargeAssistantInt,
  },
  {
    tester: dynamicTester('assistantInstructWrite'),
    renderer: customDetailsLargeAssistanWrite,
  },
  {
    tester: dynamicTester('dataTag'),
    renderer: noSpaceInput,
  },
  {
    tester: dynamicTester('location'),
    renderer: customLocation,
  },
  {
    tester: dynamicTester('folder_loc'),
    renderer: customLocationFolder,
  },
  {
    tester: dynamicTesterMatch('value'),
    renderer: noSpaceInputWCase,
  },
  {
    tester: dynamicTester('folders'),
    renderer: customFolderPicker,
  },
  // CI
  {
    tester: dynamicTester('names'),
    renderer: pickTags,
  },
  {
    tester: dynamicTester('explained'),
    renderer: customDetails,
  },
  {
    tester: dynamicTester('instructions'),
    renderer: pickTags,
  },
  {
    tester: dynamicTester('classification_type'),
    renderer: customFunctionTypeDropdown,
  },
  {
    tester: dynamicTester('examples'),
    renderer: pickTags,
  },
  {
    tester: dynamicTester('packageId'),
    renderer: CustomPackageIdRenderer,
  },
  {
    tester: dynamicTester('promptId'),
    renderer: CustomPromptId,
  },
  {
    tester: dynamicTester('contentTags'),
    renderer: customContentTagHtml,
  },
  {
    tester: dynamicTester('contentResources'),
    renderer: customResources,
  },
  {
    tester: dynamicTester('partner_custom_name'),
    renderer: customPartnerName,
  },
];

interface JSONFormProps {
  formErrors: {};
  setFormErrors: React.Dispatch<React.SetStateAction<{}>>;
  data: any;
  setData: React.Dispatch<React.SetStateAction<{}>>;
  popupData: any;
}

export const JSONForm: React.FC<JSONFormProps> = ({
  formErrors,
  setFormErrors,
  data,
  setData,
  popupData,
}) => {
  const handleFormChange = (props: any) => {
    setData(props?.data);
    setFormErrors(props?.errors);
  };

  const global = useAppSelector(selectGlobal);
  const dispatch = useAppDispatch();
  useAssistantFunctionCondition(data);

  const handleSubmit = () => {
    if (Object.keys(formErrors).length === 0) {
      // All required fields are filled, handle the form submission
      console.log('Form submitted:', data);
    } else {
      console.log('Form has errors. Cannot submit.');
    }
  };

  useEffect(() => {
    setFormErrors(formErrors || global?.formErrors);
  }, [global]);

  console.log(data);

  useEffect(() => {
    dispatch(setCurrentFormState(data));
  }, [data]);

  console.log(formErrors);

  return (
    <div className="App">
      <JsonForms
        schema={popupData?.schema}
        uischema={popupData?.uischema}
        data={data}
        renderers={renderers}
        cells={materialCells}
        onChange={handleFormChange}
      />
    </div>
  );
};
