/* eslint-disable styled-components-a11y/label-has-associated-control */
import React from 'react';
import styled from 'styled-components';
import DropdownByEndpoint from '../components/DropdownByEndpoint';

const Container = styled.div`
  display: grid;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  max-width: 900px;
  width: 100%;
  margin: 40px auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Section = styled.div`
  display: grid;
  align-items: flex-start;
`;

const FormWrapper = styled.div`
  margin-top: 20px;
`;

const FormItem = styled.div`
  margin-bottom: 20px;
  display: grid;
  gap: 10px;
  button {
    justify-self: flex-start;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`;

const PlayButton = styled.button`
  justify-self: flex-end;
  background-color: #2d2d2d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  margin-top: 10px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #1e1e1e;
  }

  img {
    width: 16px;
    height: 16px;
  }

  span {
    color: #fff;
    margin-left: 8px;
    font-size: 16px;
  }
  p {
    color: #fff;
  }
`;

export const Button: any = styled.button`
  padding: 10px 20px;
  color: #333;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.2s;
  border-bottom: 2px #0057b31c solid;
  &:disabled {
    border-bottom: 2px #0056b3 solid;
  }
  img {
    width: 10px;
  }
`;

const Error = styled.div`
  margin-top: 10px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
`;

const CodeEditorContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #282c34;
  border-radius: 8px;
  color: #61dafb;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  overflow-x: auto;
`;

const CodeEditorTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
`;

const TabsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 1rem;
`;

const data: any = {
  id: 1,
  tabs: [
    {
      name: 'Initiage Trigger',
      functions: [
        {
          type: 'dropdownByEndpoint',
          label: 'Trigger Step',
          property: 'runId',
          valueProperty: 'stepId',
          labelProperty: 'step_name',
          endpoint: '/ai/workflow/steps',
        },

        {
          type: 'textarea',
          label: 'Request Object',
          property: 'requestObject',
        },
      ],
    },
  ],
};

const RestForm = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleSelectTab = (index: number) => {
    setSelectedTab(index);
  };

  const handleFunctions = (f: any) => {
    switch (f?.type) {
      case 'dropdownByEndpoint':
        return (
          <DropdownByEndpoint
            valueProperty={f?.valueProperty}
            labelProperty={f?.labelProperty}
            endpoint={f?.endpoint}
            onChange={() => {}}
          />
        );
      case 'textarea':
        return <TextArea />;
    }
  };

  return (
    <Container>
      <TabsWrapper>
        {data.tabs?.map((tb: any, index: number) => (
          <Section>
            <Button
              onClick={() => handleSelectTab(index)}
              disabled={selectedTab === index}
            >
              {tb?.name}
            </Button>
          </Section>
        ))}
      </TabsWrapper>
      {data.tabs?.map((tb: any, index: number) => (
        <Section>
          {selectedTab === index && (
            <FormWrapper>
              {tb?.functions?.map((f: any) => (
                <FormItem>
                  <Label>{f?.label}</Label>
                  {handleFunctions(f)}
                </FormItem>
              ))}
            </FormWrapper>
          )}
        </Section>
      ))}
    </Container>
  );
};

export default RestForm;
