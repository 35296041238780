import React, { useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
// Import omitted CodeMirror components if needed
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
`;

const HTMLContainer = styled.div`
  max-width: 600px;
  img {
    width: 100%;
  }
`;

const Title = styled.h2`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1.6rem;
  margin: 1rem 0;
`;

interface HtmlEditorProps extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: string; // Assuming 'id' is of type string
}

const CustomHTMLObjectPreviewTag: React.FC<HtmlEditorProps> = (props) => {
  const [htmlContent, setHtmlContent] = useState('');
  const global = useAppSelector(selectGlobal);

  const replaceWithThose: any = {
    ...global?.htmlTagValues,
  };

  useEffect(() => {
    // This function will replace the placeholders with the actual values
    const replacePlaceholders = (html: any, replacements: any) => {
      // Iterate over all replacements and replace them in the HTML content
      Object.entries(replacements).forEach(([placeholder, value]) => {
        // Create a regular expression for the placeholder, escaping special characters
        const escapedPlaceholder = placeholder.replace(
          /[-\/\\^$*+?.()|[\]{}]/g,
          '\\$&'
        );
        const regex = new RegExp(escapedPlaceholder, 'g');
        html = html.replace(regex, value);
      });

      return html;
    };

    // Call the replacePlaceholders function and update the state
    if (global?.currentHTML) {
      const updatedHtml = replacePlaceholders(
        global.currentHTML,
        replaceWithThose
      );
      setHtmlContent(updatedHtml);
      props.handleChange(props.path, updatedHtml);
      props.handleChange('htmlTagValues', replaceWithThose);
    }
  }, [global?.currentHTML]);

  // Handler for file upload, assuming you want to keep this functionality
  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        props.handleChange(props.path, e.target?.result as string);
      };
      reader.readAsText(file);
    }
  };

  return (
    <Wrapper>
      <Title>Preview</Title>
      <HTMLContainer dangerouslySetInnerHTML={{ __html: htmlContent }} />
      {/* Uncomment the following if you need to use CodeMirror and file input */}
      {/*
      <CodeMirror
        value={props.data || ''}
        options={{
          mode: 'xml',
          theme: 'default',
          lineNumbers: true,
        }}
        onChange={(editor, data, value) => {
          props.handleChange(props.path, value);
        }}
      />
      <input
        type="file"
        accept=".html"
        onChange={handleUpload}
      />
      */}
    </Wrapper>
  );
};

export default withJsonFormsControlProps(CustomHTMLObjectPreviewTag);
