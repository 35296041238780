/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const InputRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Label = styled.label`
  margin-right: 10px;
`;

const Input = styled.input`
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 300px;
`;

const AddButton = styled.button`
  background: transparent;
  border: none;
  margin-bottom: 10px;
  align-self: flex-end;
  cursor: pointer;
  font-size: 24px;
  color: #000;

  &:hover {
    color: #555;
  }
`;

const RemoveButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #000;
  margin-left: 10px;

  &:hover {
    color: #d11a2a;
  }
`;

const TokensList = styled.div`
  margin-top: 20px;
`;

// TypeScript type for the token
type Token = {
  token: string;
  value: string;
};

// React Component
const SessionTokenComponent: React.FC<{
  onChange: (tokens: Token[]) => void;
  display: boolean;
}> = ({ onChange, display }) => {
  const [tokens, setTokens] = useState<Token[]>([]);

  const addToken = () => {
    setTokens([...tokens, { token: '', value: '' }]);
  };

  const removeToken = (index: number) => {
    const newTokens = tokens.filter((_, tokenIndex) => tokenIndex !== index);
    setTokens(newTokens);
  };

  const updateToken = (
    index: number,
    field: 'token' | 'value',
    value: string
  ) => {
    const newTokens = [...tokens];
    newTokens[index][field] = value;
    setTokens(newTokens);
  };

  useEffect(() => {
    onChange(tokens);
  }, [onChange, tokens]);

  return display ? (
    <Container>
      <AddButton onClick={addToken}>+</AddButton>
      {tokens.map((token, index) => (
        <InputRow key={index}>
          <Input
            placeholder="Token Name"
            value={token.token}
            onChange={(e) => updateToken(index, 'token', e.target.value)}
          />
          <Input
            placeholder="Token Value"
            value={token.value}
            onChange={(e) => updateToken(index, 'value', e.target.value)}
          />
          <RemoveButton onClick={() => removeToken(index)}>-</RemoveButton>
        </InputRow>
      ))}
    </Container>
  ) : (
    <div />
  );
};

export default SessionTokenComponent;
