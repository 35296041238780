const schema = {
  type: 'object',
  properties: {
    status: {
      type: 'string',
      enum: ['Required', 'Draft', 'Released'],
    },
    assistant_type: {
      type: 'string',
      title: 'Assistant Type',
    },

    // Dynamic classes
    collection_name: {
      type: 'string',
      title: 'Collection Name',
    },
    classes_tests: {
      type: 'array',
      title: 'Classes Tests',
      items: {
        type: 'object',
        properties: {
          class: {
            type: 'string',
            title: 'Class',
          },
          instruction: {
            type: 'string',
            title: 'Instruction',
            options: {
              multi: true,
            },
          },
        },
      },
    },
    class_property: {
      type: 'string',
      title: 'Class Property',
    },
    guidance_property: {
      type: 'string',
      title: 'Guidance Property',
    },
    filtersMap: {
      type: 'array',
      title: 'Filters',
      items: {
        type: 'object',
        properties: {
          token: {
            type: 'string',
            title: 'TokenName',
          },
          filter: {
            type: 'string',
            title: 'Filter Property Name',
          },
        },
      },
    },
    // Dynamic classes
    assistant: {
      type: 'string',
      title: 'Function',
    },
    version: {
      type: 'number',
      title: 'Version',
    },
    active_role: {
      type: 'string',
      title: 'Active Role',
    },
    guidance: {
      type: 'string',
      title: 'Guidance',
    },
    tokens: {
      type: 'object',
      title: 'Tokens',
      properties: {
        objective: {
          type: 'string',
          title: 'Objective',
        },
        you_are_statement: {
          type: 'string',
          title: 'You Are Statement',
        },
        class_name: {
          type: 'string',
          title: 'Class Name',
        },
        returned_object: {
          type: 'string',
          title: 'Returned Object',
        },
        returned_object_instructions: {
          type: 'string',
          title: 'Returned Object Instructions',
        },
      },
    },

    classes: {
      type: 'array',
      title: 'Classes',
      items: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
            title: 'Name',
          },
          guidance: {
            type: 'string',
            title: 'Guidance',
          },
        },
      },
    },
    enabled: {
      type: 'boolean',
      title: 'Enabled',
    },
    requestObject: {
      type: 'array',
      title: 'Request Object',
      items: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
            title: 'Name',
          },
          dataType: {
            type: 'string',
            enum: [
              'string',
              'number',
              'boolean',
              'date',
              'array of strings',
              'objects',
            ],
            title: 'Type',
          },
          fObjectId: {
            'ui:options': {
              endpoint: '/ai/workflow/objects',
              value: 'object_name',
              label: 'object_name',
              title: 'Objects',
              isMulti: false,
            },
          },
        },
      },
    },
    responseObject: {
      type: 'array',
      title: 'Response Object',
      items: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
            title: 'Name',
          },
          dataType: {
            type: 'string',
            enum: [
              'string',
              'number',
              'boolean',
              'date',
              'array of strings',
              'objects',
            ],
            title: 'Type',
          },
          fObjectId: {
            'ui:options': {
              endpoint: '/ai/workflow/objects',
              value: 'object_name',
              label: 'object_name',
              title: 'Objects',
              isMulti: false,
            },
          },
        },
      },
    },
  },
  required: ['assistant'],
};

const uischema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'AI Function',
      elements: [
        { type: 'Control', scope: '#/properties/assistantId' },

        {
          type: 'Control',
          scope: '#/properties/assistant_type',
        },
        {
          type: 'Control',
          scope: '#/properties/assistant',
        },
        // {
        //   type: 'Control',
        //   scope: '#/properties/assistantFuncTypeRead',
        // },
        {
          type: 'Control',
          scope: '#/properties/assistantInstructWrite',
        },
        {
          type: 'Control',
          scope: '#/properties/status',
        },
      ],
    },
    {
      type: 'Category',
      label: 'Request Object',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/requestObject',
          options: {
            detail: {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/name',
                },
                {
                  type: 'Control',
                  scope: '#/properties/dataType',
                },
                {
                  type: 'Control',
                  scope: '#/properties/fObjectId',
                  rule: {
                    effect: 'SHOW',
                    condition: {
                      scope: '#/properties/dataType',
                      schema: {
                        const: 'objects',
                      },
                    },
                  },
                  // Indicate that a custom renderer should be used
                  options: {
                    custom: true,
                  },
                },
              ],
            },
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Response Object',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/responseObject',
          options: {
            detail: {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/name',
                },
                {
                  type: 'Control',
                  scope: '#/properties/dataType',
                },
                {
                  type: 'Control',
                  scope: '#/properties/fObjectId',
                  rule: {
                    effect: 'SHOW',
                    condition: {
                      scope: '#/properties/dataType',
                      schema: {
                        const: 'objects',
                      },
                    },
                  },
                  // Indicate that a custom renderer should be used
                  options: {
                    custom: true,
                  },
                },
              ],
            },
          },
        },
      ],
    },
  ],
};

const data = {};

const addAssistantWithAI = (endpoint) => {
  return {
    endpoint,
    buttonTitle: 'Add Assistant',
    schema: schema,
    uischema,
    data,
    refreshPage: true,
  };
};

module.exports = addAssistantWithAI;
