import React from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import styled from 'styled-components';

const Wrapper = styled.div``;
const StepWrapper = styled.div`
  display: grid;
  gap: 5px 0;
`;
const Title = styled.p`
  font-size: 22px;
  margin: 20px 0;
  font-weight: 500;
`;
const Table = styled.div`
  padding: 1rem 0 1rem 1rem;
  margin-bottom: 10px;
  background: #33333310;
  border-radius: 5px;
`;
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 10px;
  border-bottom: 1px solid #33333328;
`;
const TableTitle = styled.p`
  color: #333;
`;
const TableMain = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px 0;
`;
const TableValue = styled.p`
  color: #333;
`;
const StepValue = styled.p`
  display: grid;
  grid-template-columns: 100px auto;
  justify-content: flex-start;
  color: #333;
`;

const Row = styled.div`
  background: beige;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2px;
`;

const Analysis = ({ data, handleChange, path }: any) => {
  return (
    <Wrapper>
      <Title>Analysis</Title>
      <Table>
        <TableHeader>
          <TableTitle>Property Name</TableTitle>
          <TableTitle>Analysis</TableTitle>
        </TableHeader>
        <TableMain>
          {data?.map((ctx: any) => (
            <Row>
              <TableValue>{ctx?.property}</TableValue>
              <StepWrapper>
                {ctx?.steps?.map((step: any) => (
                  <StepValue>
                    {step?.step} <div>| Used By: "{step?.used_by?.join()}"</div>
                  </StepValue>
                ))}
              </StepWrapper>
            </Row>
          ))}
        </TableMain>
      </Table>
    </Wrapper>
  );
};

export default withJsonFormsControlProps(Analysis);
