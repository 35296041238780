import React, { useEffect, useState } from 'react';
import Dropdown from 'react-select';
import { useDynamicQueryMutation } from '../redux/api/authSlice';
import { useAppSelector } from '../redux/hooks';
import { selectGlobal } from '../redux/state/globalSlice';
import styled from 'styled-components';

const Wrapper: any = styled.div`
  position: relative;
  z-index: ${({ zIndex }: any) => (zIndex ? zIndex : 'unset')};
`;

const Title = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #333;
`;

interface Iprops {
  endpoint: string;
  valueProperty?: string;
  labelProperty: string;
  onChange?: any;
  onOptionClick?: any;
  title?: string;
  zIndex?: number;
  pickedValue?: string;
  addWithLink?: { name: string | null; link: string | null };
  disableClear?: boolean;
}

const DropdownByEndpoint: React.FC<Iprops> = ({
  endpoint,
  valueProperty,
  labelProperty,
  onChange,
  onOptionClick,
  title,
  zIndex,
  pickedValue,
  addWithLink,
  disableClear,
}) => {
  const [options, setOptions] = useState<any>([]);

  const [dynamicQuery, result] = useDynamicQueryMutation();
  const global = useAppSelector(selectGlobal);

  // Assuming fetchOptions is a function that returns a Promise that resolves to the array of options
  const fetchOptions = async () => {
    try {
      dynamicQuery({
        endpoint: endpoint,
        method: 'POST',
        body: {
          foundObject: global?.rowObject,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (result.status === 'fulfilled') {
      setOptions(result?.data?.data);
    }
  }, [result]);

  const handleSelectChange = (selectedOption: any) => {
    if (addWithLink && selectedOption.value === addWithLink.name) {
      return window.location.replace(addWithLink?.link || '');
    }
    onChange(selectedOption);
  };

  const handleOnClick = (selectedOption: any) => {
    onOptionClick(selectedOption);
  };

  if (!pickedValue && result?.isLoading) {
    return <p>Loading...</p>;
  }

  let dropdownOptions = options.map((option: any) => ({
    value: valueProperty ? option?.[valueProperty] : option, // Assuming this is a string that uniquely identifies this option
    label: option?.[labelProperty], // The label that will be displayed in the dropdown
  }));

  if (addWithLink?.name) {
    dropdownOptions = [
      ...dropdownOptions,
      { value: addWithLink.name, label: addWithLink.name },
    ];
  }

  return (
    <Wrapper zIndex={zIndex}>
      <Title>{title}</Title>

      <Dropdown
        options={dropdownOptions}
        defaultValue={{ value: pickedValue, label: pickedValue }}
        onChange={(e) => handleSelectChange(e)}
        placeholder="Select..."
        isClearable={!disableClear}
      />
    </Wrapper>
  );
};

export default DropdownByEndpoint;
