import React from 'react';
import DropdownByEndpoint from './DropdownByEndpoint';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  max-width: 800px;
  width: 100%;
  justify-self: center;
  background: #f7f6f7;
  padding: 3rem 5rem;
  border-radius: 10px;
`;

const PlayButton = styled.button`
  justify-self: flex-end;
  background-color: #2d2d2d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  margin-top: 10px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #1e1e1e;
  }

  img {
    width: 16px;
    height: 16px;
  }

  span {
    color: #fff;
    margin-left: 8px;
    font-size: 16px;
  }
  p {
    color: #fff;
  }
`;

const PickAndSendREST = () => {
  return (
    <Wrapper>
      <DropdownByEndpoint
        title="Worklow"
        labelProperty="workflow_name"
        valueProperty="workflowId"
        pickedValue=""
        endpoint="/ai/workflow"
        onChange={(props: any) => console.log(props)}
      />
      <PlayButton disabled={false} onClick={() => console.log()}>
        <img src="/play-solid.svg" alt="" />
        <p>Run</p>
      </PlayButton>
    </Wrapper>
  );
};

export default PickAndSendREST;
