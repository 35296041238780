// Helper function to check if every element in an array is an object
function isArrayofObjects(array: any[]): boolean {
  return array.every(
    (item) => typeof item === 'object' && !Array.isArray(item) && item !== null
  );
}

// Main function to compare JSON properties
export function compareJsonProperties(
  jsonStr1: string,
  jsonStr2: string
): Record<string, any> {
  let jsonObj1: Record<string, any>;
  let jsonObj2: Record<string, any>;

  try {
    jsonObj1 = JSON.parse(jsonStr1);
    jsonObj2 = JSON.parse(jsonStr2);
  } catch (error) {
    return { status: 400, error: 'Invalid JSON format' };
  }

  const keys1 = new Set(Object.keys(jsonObj1));
  const keys2 = new Set(Object.keys(jsonObj2));

  const missing = Array.from(keys1).filter((key) => !keys2.has(key));
  const additional = Array.from(keys2).filter((key) => !keys1.has(key));
  const changed: string[] = [];

  for (const key of keys1) {
    if (keys2.has(key)) {
      // Check if the type of values for the same key is different
      if (typeof jsonObj1[key] !== typeof jsonObj2[key]) {
        changed.push(key);
      } else if (
        Array.isArray(jsonObj1[key]) &&
        isArrayofObjects(jsonObj1[key])
      ) {
        // Additional deep check for data types like list of objects
        if (!Array.isArray(jsonObj2[key]) || !isArrayofObjects(jsonObj2[key])) {
          changed.push(key);
        } else {
          // Check for mismatch in object keys within the array
          const innerKeys1 = new Set<string>();
          jsonObj1[key].forEach((item: Record<string, any>) =>
            Object.keys(item).forEach((innerKey) => innerKeys1.add(innerKey))
          );

          const innerKeys2 = new Set<string>();
          jsonObj2[key].forEach((item: Record<string, any>) =>
            Object.keys(item).forEach((innerKey) => innerKeys2.add(innerKey))
          );

          if (
            Array.from(innerKeys1).some(
              (innerKey) => !innerKeys2.has(innerKey)
            ) ||
            Array.from(innerKeys2).some((innerKey) => !innerKeys1.has(innerKey))
          ) {
            changed.push(key);
          }
        }
      }
    }
  }

  if (!missing.length && !additional.length && !changed.length) {
    return { status: 'Test Passed' };
  } else {
    return {
      status: 'Test Failed',
      missing: missing,
      additional: additional,
      changed: changed,
      error: `missing properties: ${missing}`,
    };
  }
}
