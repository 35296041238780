import React from 'react';
import styled from 'styled-components';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';

// Styled-components for the table
const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  background: #f3f3f3;
`;

const Row = styled.div`
  display: contents;
`;

const Cell = styled.div`
  padding: 8px;
  background: white;
  border: 1px solid #ccc;
`;

// This is a utility component to render each row of data
const DataRow = ({ test, answer, result }: any) => (
  <Row>
    <Cell>{test}</Cell>
    <Cell>{answer}</Cell>
    <Cell>{result}</Cell>
  </Row>
);

const TestResultsFiltered = (props: any) => {
  const { currentFormState } = useAppSelector(selectGlobal);
  // Assuming data.test_results is an array of test results
  const lastSession = currentFormState?.lastSession;
  // Filter by last session
  let testResults = currentFormState.test_results?.filter(
    (ctx: any) => ctx?.session === lastSession
  );

  return (
    <Table>
      {/* Render the header */}
      <Row>
        <Cell>Test</Cell>
        <Cell>Answer</Cell>
        <Cell>Result</Cell>
      </Row>
      {/* Render each row of test results */}
      {testResults &&
        testResults.map((testResult: any, index: number) => (
          <DataRow key={index} {...testResult} />
        ))}
    </Table>
  );
};

export default withJsonFormsControlProps(TestResultsFiltered);
