const schema = {
  type: 'object',
  properties: {
    assistant_type: {
      type: 'string',
      title: 'Assistant Type',
    },
    assistant: {
      type: 'string',
      title: 'Function',
    },

    version: {
      type: 'number',
      title: 'Version',
    },
    active_role: {
      type: 'string',
      title: 'Active Role',
    },
    guidance: {
      type: 'string',
      title: 'Guidance',
    },
    tokens: {
      type: 'object',
      title: 'Tokens',
      properties: {
        objective: {
          type: 'string',
          title: 'Objective',
        },
        you_are_statement: {
          type: 'string',
          title: 'You Are Statement',
        },
        parse_action: {
          type: 'string',
          title: 'Parse Action',
        },
        returned_object: {
          type: 'string',
          title: 'Returned Object',
        },
        returned_object_instructions: {
          type: 'string',
          title: 'Returned Object Instructions',
        },
        classes_query: {
          type: 'string',
          title: 'Classes Query',
        },
      },
    },
    classes: {
      type: 'array',
      title: 'Classes',
      items: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
            title: 'Name',
          },
          guidance: {
            type: 'string',
            title: 'Guidance',
          },
        },
      },
    },
    enabled: {
      type: 'boolean',
      title: 'Enabled',
    },
  },
  required: ['assistant'],
};

const uischema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'Function',
      elements: [
        { type: 'Control', scope: '#/properties/assistantId' },

        {
          type: 'Control',
          scope: '#/properties/assistant_type',
        },
        {
          type: 'Control',
          scope: '#/properties/assistant',
        },
        {
          type: 'Control',
          scope: '#/properties/assistantFuncTypeRead',
        },
        {
          type: 'Control',
          scope: '#/properties/assistantInstructRead',
        },
      ],
    },
    {
      type: 'Category',
      label: 'Tokens',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/tokens',
        },
      ],
    },

    {
      type: 'Category',
      label: 'Guidance',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/guidance',
        },
      ],
    },
    {
      type: 'Category',
      label: 'Tokenized Instructions',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/tokenizedInstructions',
        },
      ],
    },
    {
      type: 'Category',
      label: 'Tests Validation',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/tests_validation',
        },
      ],
    },
    {
      type: 'Category',
      label: 'Last Test',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/testFiltered_results',
        },
      ],
    },
    {
      type: 'Category',
      label: 'Old Test',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/test_results',
        },
      ],
    },
  ],
};

const data = {};

const addAssistantWithDynamic = (endpoint) => {
  return {
    endpoint,
    buttonTitle: 'Add Assistant',
    schema: schema,
    uischema,
    data,
    refreshPage: true,
  };
};

module.exports = addAssistantWithDynamic;
