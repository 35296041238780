/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';

// Define your styled components
const InputWrapper = styled.div`
  margin: 1rem 0;
  opacity: 0.5;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 2rem;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px;
`;

const AssistantIdInput = (props: ControlProps) => {
  const { id, enabled, uischema, path, handleChange, data } = props;
  const globalState = useAppSelector(selectGlobal);

  useEffect(() => {
    handleChange(path, data || globalState?.currentFormState?.assistantId);
    handleChange('workFlowDB', globalState?.currentWorkflow?.db_name);
  }, []);

  return (
    <InputWrapper>
      <StyledLabel htmlFor={id}>Function Id</StyledLabel>
      <StyledInput
        readOnly
        id={id}
        type="text"
        value={data || globalState?.currentFormState?.assistantId}
        disabled={!enabled}
      />
    </InputWrapper>
  );
};

export default withJsonFormsControlProps(AssistantIdInput);
