import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  authenticated: {},
  settings: {
    profileFlow: 'name',
    showViewAccount: false,
    tempStatePopup: {},
  },
  showSideBarPopup: false,
  sideBarFormData: null,
  showDeleteAlert: false,
  allowDeleteAction: false,
  rowObject: {},
  currentPopupData: {},
  currentFormState: {},
  currentFormStateOnUpdate: {},
  currentHTML: '',
  formErrors: '',
  menuToolFormData: {},
  showMenuToolForm: false,
  showJsonViewerPopup: false,
  jsonViewerData: {},
  functionTypeOnCondition: '',
  currentAssistant: {},
  currentWorkflow: {},
  conditionalFormData: {},
  showAssistantEditForm: false,
  htmlTagValues: {},
  currentSite: '',
};

// createAsyncThunk
// export const fetchTest = createAsyncThunk('global/fetchTest', async () => {
//   const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
//   const data = await response.json();
//   return data;
// });

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.authenticated = action.payload;
    },
    setProfileFlow: (state, action: PayloadAction<string>) => {
      state.settings.profileFlow = action.payload;
    },
    setViewAccount: (state, action: PayloadAction<boolean>) => {
      state.settings.showViewAccount = action.payload;
    },
    updateTempStatePopup: (state, action: PayloadAction<any>) => {
      state.settings.tempStatePopup = action.payload;
    },
    setShowSideBarPopup: (state, action: PayloadAction<boolean>) => {
      state.showSideBarPopup = action.payload;
      if (!action.payload) {
        state.sideBarFormData = null;
      }
    },
    setSideBarFormData: (state, action: PayloadAction<any>) => {
      state.sideBarFormData = action.payload;
    },
    setShowDeleteAlert: (state, action: PayloadAction<boolean>) => {
      state.showDeleteAlert = action.payload;
      // Clear
      if (!action.payload) {
        state.allowDeleteAction = false;
      }
    },
    setAllowDeleteAction: (state, action: PayloadAction<boolean>) => {
      state.allowDeleteAction = action.payload;
    },
    setRowObject: (state, action: PayloadAction<any>) => {
      state.rowObject = action.payload;
    },
    setCurrentPopupData: (state, action: PayloadAction<any>) => {
      state.currentPopupData = action.payload;
    },
    setCurrentFormState: (state, action: PayloadAction<any>) => {
      state.currentFormState = action.payload;
    },
    setCurrentHTML: (state, action: PayloadAction<string>) => {
      state.currentHTML = action.payload;
    },
    setFormErrorsRedux: (state, action: PayloadAction<any>) => {
      state.formErrors = action.payload;
    },
    setMenuToolFormData: (state, action: PayloadAction<any>) => {
      state.menuToolFormData = action.payload;
    },
    setShowMenuToolForm: (state, action: PayloadAction<boolean>) => {
      state.showMenuToolForm = action.payload;
    },
    setShowJsonViewerPopup: (state, action: PayloadAction<boolean>) => {
      state.showJsonViewerPopup = action.payload;
    },
    setJsonViewerData: (state, action: PayloadAction<any>) => {
      state.jsonViewerData = action.payload;
    },
    setFunctionTypeOnCondition: (state, action: PayloadAction<string>) => {
      state.functionTypeOnCondition = action.payload;
    },
    setCurrentAssistantState: (state, action: PayloadAction<any>) => {
      state.currentAssistant = action.payload;
    },
    setCurrentWorkFlowState: (state, action: PayloadAction<any>) => {
      state.currentWorkflow = action.payload;
    },
    setConditionalFormData: (state, action: PayloadAction<any>) => {
      state.conditionalFormData = action.payload;
    },
    setShowAssistantEditForm: (state, action: PayloadAction<boolean>) => {
      state.showAssistantEditForm = action.payload;
    },
    setCurrentFormStateOnUpdate: (state, action: PayloadAction<any>) => {
      state.currentFormStateOnUpdate = action.payload;
    },
    setHtmlTagValues: (state, action: PayloadAction<any>) => {
      state.htmlTagValues = { ...state.htmlTagValues, ...action.payload };
    },
    setCurrentSite: (state, action: PayloadAction<string>) => {
      state.currentSite = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setUser,
  setProfileFlow,
  setViewAccount,
  setCurrentAssistantState,
  setCurrentWorkFlowState,
  updateTempStatePopup,
  setShowSideBarPopup,
  setSideBarFormData,
  setShowDeleteAlert,
  setAllowDeleteAction,
  setRowObject,
  setCurrentPopupData,
  setCurrentFormState,
  setCurrentHTML,
  setFormErrorsRedux,
  setMenuToolFormData,
  setShowMenuToolForm,
  setShowJsonViewerPopup,
  setJsonViewerData,
  setFunctionTypeOnCondition,
  setConditionalFormData,
  setShowAssistantEditForm,
  setCurrentFormStateOnUpdate,
  setHtmlTagValues,
  setCurrentSite,
} = globalSlice.actions;
export const selectGlobal = (state: any) => state.global;
export default globalSlice.reducer;
