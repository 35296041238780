import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import { useAppDispatch } from '../redux/hooks';
import {
  setAllowDeleteAction,
  setShowDeleteAlert,
} from '../redux/state/globalSlice';

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  position: absolute;
  justify-self: center;
  align-self: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  z-index: 9;
  background: #64646449;
`;

const Container = styled.div`
  justify-self: center;
  align-self: center;
  width: 450px;
  overflow: hidden;
  border-radius: 8px;
`;

const Header = styled.div`
  background: #f35221;
  padding: 2rem;
  color: #fff;
`;

const Hero = styled.div`
  background: #fff;
  padding: 2rem;
  font-size: 1.2rem;
  color: #565656;
`;

const Footer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-end;
  gap: 1rem;
  background: #fff;
  padding: 2rem;
`;

interface IDeleteProps {
  setState?: React.Dispatch<React.SetStateAction<boolean>>;
  name?: string;
}

const DeleteWarning: React.FC<IDeleteProps> = ({ setState, name }) => {
  const dispatch = useAppDispatch();

  const closePopup = () => {
    dispatch(setShowDeleteAlert(false));
  };
  return (
    <Wrapper>
      <Container>
        <Header>Confirm Deletion</Header>
        <Hero>
          <div>Are you sure you want to delete the row?</div>
          <div>
            Deleting the record cannot be undone, and all data will be lost
          </div>
        </Hero>
        <Footer>
          <Button
            text="Cancel"
            theme="pageCancel"
            onClick={() => closePopup()}
          />
          <Button
            text="Delete"
            theme="danger"
            onClick={() => {
              closePopup();
              dispatch(setAllowDeleteAction(true));
            }}
          />
        </Footer>
      </Container>
    </Wrapper>
  );
};

export default DeleteWarning;
