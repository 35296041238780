import React from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';

const Label = styled.label`
  color: #333;
  font-size: 12px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

interface ImageUploadControlProps {
  data: string;
  handleChange: (path: string, value: string) => void;
  path: string;
  id: any;
  label: any;
}

const CustomDetails: React.FC<ImageUploadControlProps> = ({
  data, // Provide a default value of an empty string if data is undefined
  handleChange,
  path,
  id,
  label,
}) => {
  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    handleChange(path, event.target.value);
  };

  return (
    <div>
      <Label htmlFor={id}>{label}</Label>
      <Textarea id={id} value={data} onChange={handleTextareaChange} />
    </div>
  );
};

export default withJsonFormsControlProps(CustomDetails);
