/* eslint-disable styled-components-a11y/label-has-associated-control */
/* eslint-disable styled-components-a11y/no-onchange */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { config } from '../../config/config';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';

const Wrapper = styled.div``;

interface SidebarBackgroundProps {
  data: any;
  handleChange: (path: string, value: any) => void;
  path: string;
}

interface ResponseItem {
  name: string;
  url: string;
}

const Select = styled.select`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
`;

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
  text-transform: capitalize;
`;

const CustomBackgroundSideBar: React.FC<SidebarBackgroundProps> = ({
  data,
  handleChange,
  path,
}) => {
  const [option, setOption] = useState(''); // 'hex' or 'background'
  const [hexColor, setHexColor] = useState('');
  const [responseData, setResponseData] = useState<ResponseItem[]>([]);

  const global = useAppSelector(selectGlobal);
  const condition = global?.rowObject?.[path]?.includes('http')
    ? 'background'
    : 'hex';

  useEffect(() => {
    const fetchDataWithAxios = async () => {
      try {
        const response = await axios.post<any>(
          config.API_MAIN + '/getResourceGroup?type=image',
          data
        );
        setResponseData(response.data?.data);
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    };

    fetchDataWithAxios();
  }, [data]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setOption(event.target.value);
  };

  const handleHexColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHexColor(event.target.value);
    handleChange(path, event.target.value);
  };

  const handleBackgroundChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    handleChange(path, event.target.value);
  };

  useEffect(() => {
    setOption(condition);
    if (condition === 'hex') {
      return setHexColor(global?.rowObject?.[path]);
    }
    setResponseData([global?.rowObject?.[path]]);
  }, [global?.rowObject]);

  return global?.currentFormState?.template !== 'page_with_no_sidebar' ? (
    <Wrapper>
      <Label>{path?.replace('_', ' ')}</Label>
      <Select value={condition} onChange={handleOptionChange}>
        <option value="">Select an option</option>
        <option value="hex">Hex Color</option>
        <option value="background">Background</option>
      </Select>
      {option === 'hex' && (
        <Input
          type="text"
          value={hexColor}
          onChange={handleHexColorChange}
          placeholder="Hex Color"
        />
      )}
      {option === 'background' && responseData?.length > 0 && (
        <Select
          value={global?.rowObject?.[path]}
          onChange={handleBackgroundChange}
        >
          <option value="">Please select...</option>
          {responseData?.map((item, index) => (
            <option key={index} value={item.url}>
              {item.name}
            </option>
          ))}
        </Select>
      )}
    </Wrapper>
  ) : (
    <div />
  );
};

export default withJsonFormsControlProps(CustomBackgroundSideBar);
